/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllFeedbackQuestion } from '../apicalls/feedback';
export const GET_ALL_FEEDBACK_QUESTION = 'GET_ALL_FEEDBACK_QUESTION';

export const getAllFeedbackQuestionsAction = createAsyncThunk(
  GET_ALL_FEEDBACK_QUESTION,
  async (feedbackQuestionId) => {
    try {
      const response = await getAllFeedbackQuestion(feedbackQuestionId);
      // eslint-disable-next-line no-console
      if (response.status.success) {
        return { questions: response.result, status: response.status };
      } else {
        return { questions: [], status: {} };
      }
    } catch (error) {
      return { questions: [], status: {} };
    }
  }
);
const feedbackSlice = createSlice({
  name: 'feedback',
  initialState: {
    questions: null,
    status: null
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFeedbackQuestionsAction.fulfilled,(state, action)=>{
        state.questions = action.payload.questions;
        state.status = action.payload.status;
    });
  },
});

export default feedbackSlice.reducer;
