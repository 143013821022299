/* eslint-disable new-cap */
import { Tabs } from 'antd';
import React, { useState } from 'react';

import AddFeedBack from './AddFeedBack';
import AddQuestions from './AddQuestions';

import './styles.css';

const Steps = () => {
  const [current, setCurrent] = useState(1);
  const [feedbackId, setFeedbackId] = useState('');

  const items = [
    {
      key: 1,
      label: `Add Course`,
      children: (
        <AddFeedBack setCurrent={setCurrent} setFeedbackId={setFeedbackId} />
      ),
    },
    {
      key: 2,
      label: `Feedback Questions`,
      children: (
        <AddQuestions setCurrent={setCurrent} feedbackId={feedbackId} />
      ),
    },
  ];

  return (
    <diV>
      <div style={{ fontWeight: 600, fontSize: 24 }}>
        Add a course to get started!
      </div>
      <div className='divider'></div>
      <Tabs
        style={{ marginTop: '2rem' }}
        defaultActiveKey={1}
        items={items}
        activeKey={current}
      />
      ;
    </diV>
  );
};
export default Steps;

