/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import { Row, Col, Table, message } from 'antd';
import CustomSearch from '../../components/Search/CustomSearch';
import { ExportButton } from '../../components/customButtons/customButtons';
import PageTitle from '../../components/PageTitle';
import Divider from '../../components/Divider';
import { getFeedbackReportById } from '../../apicalls/feedback';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from '../../redux/loaderSlice';
import { useParams } from 'react-router-dom';

const columns = [
  {
    title: 'User',
    dataIndex: 'name',
    render: (_text, record) => {
      return ` ${record?.user?.name} `;
    },
  },
  {
    title: 'Overall Rating',
    dataIndex: 'rating',
  },
  {
    title: 'Course Name',
    dataIndex: 'course_id',
    render: (_text, record) => {
      return ` ${record?.course_id?.course_name} `;
    },
  },
  {
    title: 'Additional Comments',
    dataIndex: 'description',
  },
];

const nestedColumns = [
  {
    title: 'Feedback Question',
    dataIndex: 'question',
    render: (_text, data, index) => ` ${index + 1}. ${data?.question} `,
  },
  {
    title: 'Rating',
    dataIndex: 'rating',
  },
];

const ViewFeedBack = () => {
  const dispatch = useDispatch();
  const [feedbackReports, setFeedbackReports] = useState([]);
  const params = useParams();
  const feedbackId = params.id;

  const onSearch = (e) => {
    const value = e.target.value;
    if (e.key === 'Enter' || value.length === 0) {
      getFeedbackReports(value);
    }
  };

  useEffect(() => {
    getFeedbackReports('');
  }, []);

  const getFeedbackReports = async (searchValue) => {
    try {
      dispatch(ShowLoading());
      const response = await getFeedbackReportById(feedbackId, searchValue);
      dispatch(HideLoading());
      if (response?.status?.success) {
        const feedbackReportsWithKeys = response?.result?.map((each) => {
          return {
            ...each,
            key: each?.course_id?._id,
          };
        });
        setFeedbackReports(feedbackReportsWithKeys);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const expandedRowRender = (record) => {
    const nestedData = record?.result?.map((each) => {
      return {
        ...each,
        key: each?._id,
      };
    });

    return (
      <Table
        columns={nestedColumns}
        showHeader={true}
        dataSource={nestedData || []}
        className='mt-2'
        pagination={false}
        size='small'
      />
    );
  };
  const rowClassName = (record, index) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };

  return (
    <div>
      <PageTitle title='Feedback Reports' />
      <Divider />
      <Row style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Col span={11}>
          <CustomSearch
            // onChange={onSearch}
            placeholder={'Search Users'}
            onKeyUp={onSearch}
          />
        </Col>
        <Col>
          <Col span={8}>
            <ExportButton onClick={() => {}} />
          </Col>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={feedbackReports || []}
        rowClassName={rowClassName}
        className='mt-2'
        expandedRowRender={expandedRowRender}
      />
    </div>
  );
};

export default ViewFeedBack;
