/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPercentage } from '../../utils/formatter';
import lodash from 'lodash'

import './styles.css';


const Card = (props) => {
  const { courseCode, trainerNameList, courseName, courseDuration,id, attemptedUserList,accessUserList} = props;
  const attemptedUserLength=lodash.size(attemptedUserList);
  const accessUserLength=lodash.size(accessUserList);
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  
  const { user } = useSelector((state) => state.users);
  function checkUserType(){
   return !!(user.isAdmin||user.isSuperAdmin);
  }

  const handleNavigation =()=>{
    if(checkUserType()){
      navigate(`/admin/feedback/view/${id}`)
    }else{
      navigate(`/user/feedback/give/${id}`)
      // dispatch(getAllFeedbackQuestionsAction(id))
    }

  }
  return (
    <>
      <div className='Card'>
        <div className={'card-header'}>
          <h1>
            {courseName?.length > 20
              ? courseName?.slice(0, 20) + '...'
              : courseName}
          </h1>
        </div>
        <div className='card-content'>
          <div
            style={{ display: 'flex', width: '95%', flexDirection: 'column' }}
          >
            {trainerNameList && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginBottom: '1.2rem',
                }}
              >
                <div
                  style={{
                    width: '60%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    lineHeight: '1rem',
                  }}
                >
                  <p className='Card-Body-first-Text'>Trainer Name</p>
                </div>
                <div>
                  <p className='Card-Body-Text'>{trainerNameList[0]}</p>
                </div>
              </div>
            )}
            {courseCode && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginBottom: '1.2rem',
                }}
              >
                <div
                  style={{
                    width: '60%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    lineHeight: '1rem',
                  }}
                >
                  <p className='Card-Body-first-Text'>Course Id</p>
                </div>
                <div>
                  <p className='Card-Body-Text'>{courseCode}</p>
                </div>
              </div>
            )}
            {courseDuration && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginBottom: '1.2rem',
                }}
              >
                <div
                  style={{
                    width: '60%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    lineHeight: '1rem',
                  }}
                >
                  <p className='Card-Body-first-Text'>Duration</p>
                </div>
                <div>
                  <p className='Card-Body-Text'>{courseDuration}</p>
                </div>
              </div>
            )}
           {checkUserType()&&<div>
              <h4>{getPercentage(attemptedUserLength, accessUserLength)}% Feedback received</h4>
              <div className='progress'>
                <div
                  className='bar'
                  style={{
                    width: `${getPercentage(attemptedUserLength, accessUserLength)}%`,
                  }}
                >
                  <p className='percent'></p>
                </div>
              </div>
            </div>}
          </div>
          <div className={'card-footer'}>
            <button onClick={() => handleNavigation()}>
              {checkUserType()?'View Feedback':'Give Feedback'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
