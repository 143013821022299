/* eslint-disable new-cap */
/* eslint-disable react/prop-types */
import { message, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addQuestionToExam } from '../../../apicalls/exams';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import './styles.css';
import csv from 'csvtojson';
import { IMAGES } from '../../../assets/images';

function ImportQuestionsFileModal({
  showImportQuestionsModal,
  setShowImportQuestionsModal,
  refreshData,
  examId,
}) {
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([]);
  const [file, setFile] = useState('');
  const handleFileChange = async (event) => {
    const csvFile = event.target.files[0];
    setFile(csvFile.name);
    const reader = new FileReader();
    reader.onload = async (event) => {
      const fileContents = event.target.result;
      const jsonArray = await csv().fromString(fileContents);

      const questions = jsonArray.map((question) => {
        const {
          Question,
          'Option A': optionA,
          'Option B': optionB,
          'Option C': optionC,
          'Option D': optionD,
          Answer,
          Weightage,
        } = question;
        return {
          name: Question,
          options: { A: optionA, B: optionB, C: optionC, D: optionD },
          correctOption: Answer,
          weightage: Weightage,
        };
      });

      setQuestions(questions);
    };
    reader.readAsText(csvFile);
  };
  const handleSubmit = async () => {
    try {
      dispatch(ShowLoading());

      const requiredPayload = { examId: examId, questions: questions };

      const response = await addQuestionToExam(requiredPayload);

      if (response.status.success) {
        message.success(response?.status?.message);
        refreshData();
        setShowImportQuestionsModal(false);
      } else {
        message.error(response.error.message);
      }

      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  return (
    <Modal
      title={<h4 className='modal-title'>Import Questions</h4>}
      style={{ fontSize: 24, fontWeight: 800 }}
      open={showImportQuestionsModal}
      maskStyle={{ backgroundColor: 'rgba(30, 34, 37, 0.8)' }}
      footer={false}
      onCancel={() => {
        setShowImportQuestionsModal(false);
      }}
    >
      <div style={{ height: '15rem' }}>
        <div className='upload-file-style'>
          <img
            src={IMAGES.uploadIcon}
            style={{ height: '2rem', width: '2.5rem' }}
            alt='upload_icon'
          ></img>
          <label className='browse-files-label'>
            <input
              style={{ display: 'none' }}
              type='file'
              accept='.csv'
              onChange={handleFileChange}
            />
            Browse files
          </label>
          {file && (
            <p
              style={{
                fontSize: 14,
                color: 'darkgray',
                fontWeight: 400,
                marginTop: 5,
              }}
            >
              <span style={{ color: 'grey' }}>Selected File: </span> {file}
            </p>
          )}
        </div>

        <div
          className='flex justify-end'
          style={{
            marginTop: 20,
            position: 'absolute',
            bottom: '1.5rem',
            right: '1.5rem',
          }}
        >
          <button
            style={{ fontSize: 14, fontWeight: 600 }}
            className='next-prev-button next-button'
            onClick={handleSubmit}
          >
            Upload
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ImportQuestionsFileModal;
