import CryptoJS from 'crypto-js';

function decryptFunction(encryptedData) {
  const bytes = CryptoJS.AES.decrypt(
    encryptedData,
    process.env.REACT_APP_ENCRYPT_SECRET_KEY
  );
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
}

const generateRandomKey = () => {
  const randomNumber = Math.random();
  const uniqueId = Date.now().toString();
  return `${randomNumber}-${uniqueId}`;
};
function formatNumber(num) {
  try {
    if (num && Number(num) !== 0) {
      const parsedNum = Number(num);
      if (Number.isInteger(parsedNum)) {
        return String(parsedNum); // Return the whole number as a string
      } else {
        return parsedNum.toFixed(3); // Return the number with 3 decimal places
      }
    } else {
      return '0';
    }
  } catch {
    return String(num);
  }
}
export { decryptFunction,generateRandomKey ,formatNumber};
