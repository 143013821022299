import React from 'react';
import { Form, Input, Tooltip, Typography, Card, Divider, message } from 'antd';
import PageTitle from '../../../components/PageTitle';
import { getUserInfo, updateUserInfo } from '../../../apicalls/users';
import { useSelector, useDispatch } from 'react-redux';
import { SetUser } from '../../../redux/usersSlice';
import { CommonButton } from '../../../components/customButtons/customButtons';

const UserProfile = () => {
  const { user } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const onFinish = (values) => {
    const updatedInfo = {
      name: values?.Name,
    };
    updateUserInfo(updatedInfo).then((response) => {
      if (response?.result?.acknowledged) {
        getUserInfo().then((res) => {
          // eslint-disable-next-line new-cap
          dispatch(SetUser(res.result));
        });
        return message.success(response?.status?.message);
      } else {
        return message.error('No changes');
      }
    });
  };

  return (
    <>
      <PageTitle title={`Profile`} />
      <div className='divider'></div>
      <Card
        style={{
          position: 'relative',
          width: 800,
          margin: 'auto',
          top: 30,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Tooltip title='Useful information'>
          <Typography>Edit Profile</Typography>
        </Tooltip>
        <Divider />
        <Form
          name='complex-form'
          onFinish={onFinish}
          style={{
            maxWidth: 500,
            width: 400,
          }}
        >
          <Form.Item
            name='Email'
            rules={[
              {
                required: false,
                message: 'Username is required',
              },
            ]}
          >
            <Input className='input-style' placeholder={user?.email} disabled />
          </Form.Item>

          <Form.Item
            name='Name'
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input className='input-style' defaultValue={user?.name} />
          </Form.Item>
          <Form.Item style={{ justifyContent: 'center', display: 'flex' }}>
            <CommonButton label='Update Profile' type='submit'></CommonButton>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};
export default UserProfile;
