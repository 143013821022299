/* eslint-disable new-cap */
import React, { useState } from 'react';
import { Modal, message } from 'antd';

import './styles.css';
import { DeleteOutlined } from '@ant-design/icons';
import { COLORS } from '../../constants';
import { generateRandomKey } from '../../utils/commonFunctions/commonFunctions';
import { addQuestionToFeedback } from '../../apicalls/feedback';
import { useDispatch } from 'react-redux';
import { HideLoading } from '../../redux/loaderSlice';

function AddQuestionModel({
  showAddQuestionModal,
  setShowAddQuestionModal,
  courseId,
  refreshData,
}) {
  const [questions, setQuestions] = useState([
    { id: generateRandomKey(), value: '' },
  ]);
  const dispatch = useDispatch();

  const handleProceed = async () => {
    const filteredEmptyQuestions = questions?.filter(
      (each) => each?.value !== ''
    );
    const finalQuestionsList = filteredEmptyQuestions?.map((each) => {
      return { name: each?.value };
    });

    try {
      const feedbackQuestionPayload = {
        courseId: courseId,
        questions: finalQuestionsList,
      };

      const response = await addQuestionToFeedback(feedbackQuestionPayload);
      if (response.status.success) {
        setShowAddQuestionModal(false);
        refreshData();
        message.success(response.status.message);
      } else {
        message.error(response.error.message);
      }

      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
      setShowAddQuestionModal(false);
    }
  };

  const handleAddMore = () => {
    const arr = [...questions];
    arr.push({ id: generateRandomKey(), value: '' });
    setQuestions(arr);
  };

  const handleDelete = (id) => {
    const newArr = questions?.filter((each) => each.id !== id);
    setQuestions(newArr);
  };
  return (
    <Modal
      title={<h4 className='modal-title'>Add Question</h4>}
      open={showAddQuestionModal}
      maskStyle={{ backgroundColor: 'rgba(30, 34, 37, 0.8)' }}
      footer={false}
      onCancel={() => {
        setShowAddQuestionModal(false);
      }}
    >
      <div>
        {questions?.map((each) => (
          <div
            key={each.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              maxHeight: 50,
              marginBottom: 10,
            }}
          >
            <input
              className='input-box'
              type='text'
              onChange={(e) => {
                each.value = e.target.value;
              }}
            />
            <DeleteOutlined
              style={{ marginLeft: 5, color: COLORS.alert_light_red }}
              onClick={() => handleDelete(each.id)}
            />
          </div>
        ))}
        <div
          onClick={handleAddMore}
          style={{ marginTop: 5, color: COLORS.primary_green }}
        >
          Add more +
        </div>

        <div className='flex justify-end' style={{ marginTop: 20 }}>
          <button
            className='next-prev-button next-button'
            onClick={handleProceed}
          >
            Proceed
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AddQuestionModel;

