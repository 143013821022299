/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row , Empty } from 'antd';
import AssesmentCard from './AssesmentCard';

const ActiveAssesments = ({ AllActiveAssesments, exams, setExams }) => {
  return (
    <div>
      <Row gutter={[16, 16]}>
        {AllActiveAssesments?.length>0?AllActiveAssesments?.map((item, index) => (
          <Col key={index}>
            <AssesmentCard
              item={item}
              category={item.category}
              duration={item.duration}
              startExamDate={item.startExamDate}
              isInProgress={item.startExamDate ? false : true}
              endExamDate={item.endExamDate}
              buttonName='Preview'
              isDisabled={item.startExamDate ? false : true}
              attemptedUsersCount={item.attempted_users_count}
              totalUserCount={item.total_user_count}
              setExams={setExams}
              status={item.status} 
            />
          </Col>
        )):<Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col
          className='gutter-row'
          span={24}
          style={{width:'100vw'}}
        >
           <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
      </Row>}
      </Row>
    </div>
  );
};


export default ActiveAssesments;
