import React from 'react';
import './styles.css';
import CustomHeader from './header';
import SideBar from './SideBar';

const AppLayout = ({ children, ...restProps }) => {
  return (
    <main>
      <header>
        <CustomHeader />
      </header>
      <aside>
        <SideBar />
      </aside>
      <section>
        <div className='content'>
        {children}
        </div>
      </section>
    </main>
  );
};

export default AppLayout;
