/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
import React, { useState, useRef, useEffect } from 'react';
import { message } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { hideCloseExam, getAllExams } from '../../../apicalls/exams';
import { getPercentage } from '../../../utils/formatter';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';

import Images from '../../../assets';
import './styles.css';

const AssesmentCard = (props) => {
  const {
    item,
    isDisabled,
    duration,
    startExamDate,
    endExamDate,
    buttonName,
    isInProgress,
    attemptedUsersCount,
    totalUserCount,
    setExams,
    status,
  } = props;
  const { _id, name } = item;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();
  const formtedTime = moment.utc(duration * 60 * 1000).format('HH:mm:ss');
  const [openMenu, setOpenMenu] = useState(false);
  const handleColseExam = async (examId, status) => {
    try {
      const response = await hideCloseExam(examId, status);
      if (response?.status?.success) {
        getExams();
        message.success(response?.status?.message);
        setOpenMenu(false);
      } else {
        message.error(response?.status?.message);
        setOpenMenu(false);
      }
    } catch (error) {
      message.error(error.message);
      setOpenMenu(false);
    }
  };
  const handlePermanentClose = async (examId, status) => {
    try {
      const response = await hideCloseExam(examId, status);
      if (response?.status?.success) {
        getExams();
        message.success(response?.status?.message);
        setOpenMenu(false);
      } else {
        message.error(response?.status?.message);
        setOpenMenu(false);
      }
    } catch (error) {
      message.error(error.message);
      setOpenMenu(false);
    }
  };
  const handleReopenClosedAssisment = async (examId, status) => {
    try {
      const response = await hideCloseExam(examId, status);
      if (response?.status?.success) {
        getExams();
        message.success(response?.status?.message);
        setOpenMenu(false);
      } else {
        message.error(response?.status?.message);
        setOpenMenu(false);
      }
    } catch (error) {
      message.error(error.message);
      setOpenMenu(false);
    }
  };
  const getExams = async () => {
    try {
      dispatch(ShowLoading());
      setTimeout(async () => {
        const response = await getAllExams();
        if (response?.status?.success) {
          setExams(() => response.result);
        } else {
          message.error(response?.status?.message);
        }
        dispatch(HideLoading());
      }, 100);
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', (e) => {
      if (ref?.current?.contains(e.target)) {
        setOpenMenu(false);
      }
    });
  }, [ref]);

  return (
    <>
      <div className='Card' ref={ref}>
        <div
          className={
            status.toLowerCase() === 'hide'
              ? 'card-header-close'
              : 'card-header'
          }
        >
          <h1>{name?.length > 24 ? name?.slice(0, 24) : name}</h1>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setOpenMenu(!openMenu)}
          >
            <img src={Images.CardVerticalMenu} alt='menu' />
          </span>
        </div>
        <div className='card-content'>
          <div
            style={{ display: 'flex', width: '95%', flexDirection: 'column' }}
          >
            {(startExamDate || isInProgress) && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginBottom: '1.22rem',
                }}
              >
                <div
                  style={{
                    width: '60%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    lineHeight: '1rem',
                  }}
                >
                  <p className='Card-Body-first-Text'>Start Date</p>
                </div>
                <div>
                  <p className='Card-Body-Text'>
                    {isInProgress
                      ? '................'
                      : moment(startExamDate).format('MM/DD/YYYY')}
                  </p>
                </div>
              </div>
            )}

            {(endExamDate || isInProgress) && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginBottom: '1.2rem',
                }}
              >
                <div
                  style={{
                    width: '60%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    lineHeight: '1rem',
                  }}
                >
                  <p className='Card-Body-first-Text'>End Date</p>
                </div>
                <div>
                  <p className='Card-Body-Text'>
                    {isInProgress
                      ? '................'
                      : moment(endExamDate).format('MM/DD/YYYY')}
                  </p>
                </div>
              </div>
            )}
            {duration && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginBottom: '1.2rem',
                }}
              >
                <div
                  style={{
                    width: '60%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    lineHeight: '1rem',
                  }}
                >
                  <p className='Card-Body-first-Text'>Duration</p>
                </div>
                <div>
                  <p className='Card-Body-Text'>{formtedTime}</p>
                </div>
              </div>
            )}
            <div>
              <h4>
                Completion Rate{' '}
                {getPercentage(attemptedUsersCount, totalUserCount)}%
              </h4>
              <div className='progress'>
                <div
                  className='bar'
                  style={{
                    width: `${getPercentage(
                      attemptedUsersCount,
                      totalUserCount
                    )}%`,
                  }}
                >
                  <p className='percent'></p>
                </div>
              </div>
            </div>
          </div>
          <div className={isDisabled ? 'deactive-button ' : 'card-footer'}>
            <button onClick={() => navigate(`/admin/preview/${_id}`)}>
              {buttonName}
            </button>
          </div>
        </div>
      </div>
      {openMenu && (
        <div className='menu_container'>
          <div className='menu_button_wrapper'>
            {status !== 'active' ? (
              <span
                className='permanent_close'
                onClick={() => handleReopenClosedAssisment(_id, 'active')}
              >
                Reopen
              </span>
            ) : (
              <span
                className='partial_close'
                onClick={() => handleColseExam(_id, 'hide')}
              >
                Partial Close{' '}
              </span>
            )}
            <span
              className='permanent_close'
              onClick={() => handlePermanentClose(_id, 'closed')}
            >
              Permanent Close
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AssesmentCard;
