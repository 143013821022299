import axiosInstance from '.';

export const getUserInfo = async () => {
  try {
    const response = await axiosInstance.get(`/api/users/get-user-info`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get(`/api/users/get-all-users`);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateUserInfo = async (body) => {
  try {
    const response = await axiosInstance.put(`/api/users/update-user`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const getAllUsersAndAdmins = async (search) => {
  let url = `/api/users/get-users-for-super-admins`;
  if (search) {
    url = `/api/users/get-users-for-super-admins?userName=${search}`;
  }
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const updateAdminStatus = async (body) => {
  try {
    const response = await axiosInstance.put(`/api/users/put-admins`, body);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
