/* eslint-disable new-cap */
import { message, Table, Tag } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteExamById, getAllExams } from '../../../apicalls/exams';
import PageTitle from '../../../components/PageTitle';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AddButton } from '../../../components/customButtons/customButtons';

function Exams() {
  const navigate = useNavigate();
  const [exams, setExams] = React.useState([]);
  const dispatch = useDispatch();

  const getExamsData = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getAllExams();
      dispatch(HideLoading());
      if (response.status.success) {
        setExams(response.result);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const deleteExam = async (examId) => {
    try {
      dispatch(ShowLoading());
      const response = await deleteExamById({
        examId,
      });

      dispatch(HideLoading());
      if (response.status.success) {
        message.success(response.status.message);
        getExamsData();
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };
  const columns = [
    {
      title: 'Assessment Name',
      dataIndex: 'name',
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Total Marks',
      dataIndex: 'totalMarks',
    },
    {
      title: 'Passing Marks',
      dataIndex: 'passingMarks',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) =>
        record?.freezeExam ? (
          <Tag style={{ borderRadius: 4 }} color='purple'>
            Published
          </Tag>
        ) : (
          <div className='flex gap-2'>
            <DeleteOutlined onClick={() => deleteExam(record._id)} />
            <EditOutlined
              onClick={() => navigate(`/admin/assessment/edit/${record._id}`)}
            />
          </div>
        ),
    },
  ];
  useEffect(() => {
    getExamsData();
  }, []);

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };
  return (
    <div>
      <div className='flex justify-between mt-2 items-end'>
        <PageTitle title='Assessment' />
        <AddButton
          label='Add Assessment'
          onClick={() => navigate('/admin/assessment/add')}
        />
      </div>
      <div className='divider'></div>
      <Table
        columns={columns}
        dataSource={exams?.all_active_exams}
        rowClassName={rowClassName}
      />
    </div>
  );
}

export default Exams;
