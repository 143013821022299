/* eslint-disable no-console */
/* eslint-disable new-cap */

import React, { useEffect, useState } from 'react';
import {
  AddButton,
  CommonButton,
  PrevButton,
} from '../../components/customButtons/customButtons';
import ImportQuestionsModal from './ImportQuestionsModel';
import AddQuestionModel from './AddQuestionModel';
import { DatePicker, Form, Modal, Radio, Select, Table, message } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { EmailSentModal } from '../../components/Modals/EmailSentModal';
import { HideLoading, ShowLoading } from '../../redux/loaderSlice';
import {
  editFeedbackReport,
  getFeedbackQuestions,
} from '../../apicalls/feedback';
import { getAllUsers } from '../../apicalls/users';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import lodash from 'lodash';
import { useNavigate } from 'react-router-dom';

const AddQuestions = (props) => {
  const { setCurrent, feedbackId } = props;

  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [showImportQuestionsModal, setShowImportQuestionsModal] =
    useState(false);

  const [showSubmitModel, setShowSubmitModel] = useState(false);

  const navigate = useNavigate();

  const [showEmailSentModal, setShowEmailSentModal] = useState(false);
  const [questions, setQuestions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (feedbackId) {
      getQuestions();
    }
  }, []);

  const onNotifyClicked = async (value) => {
    const deadLine = moment(value.feedbackDeadline._d).format(
      'YYYY-MM-DD HH:mm'
    );
    const response = await editFeedbackReport({
      courseId: feedbackId,
      end_date: deadLine,
      notify_all: value.notifyUsersOptions === 'all' ? true : false,
      access_user_list: value.selectedUsers,
    });

    if (response.status.success) {
      await setShowSubmitModel(false);
      setShowEmailSentModal(true);
      setTimeout(() => {
        navigate('/admin/feedback');
      }, 2000);
    } else {
      message.error(response.error.message);
    }
  };
  const getQuestions = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getFeedbackQuestions(feedbackId);

      dispatch(HideLoading());
      if (response?.status?.success) {
        setQuestions(response?.result);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const isButtonDisabled = () => {
    return lodash.isEmpty(questions);
  };

  const handlePrev = () => {
    setCurrent((current) => current - 1);
  };

  const questionsColumns = [
    {
      title: 'Feedback Questions',
      dataIndex: 'name',
    },
  ];

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };
  return (
    <div className='flex flex-col'>
      <div className='flex justify-end' style={{ marginBottom: 20, flex: 1 }}>
        <AddButton
          label='Add Question'
          onClick={() => setShowAddQuestionModal(true)}
        />
        <div className='import-button-container'>
          <button
            className='add-questions-button'
            type='button'
            onClick={() => setShowImportQuestionsModal(true)}
          >
            <ImportOutlined style={{ marginRight: 8, fontSize: '1.2rem' }} />
            <span>Import Questions</span>
          </button>
        </div>
      </div>

      <Table
        columns={questionsColumns}
        dataSource={questions}
        rowClassName={rowClassName}
        style={{ height: '100%', marginBottom: '5rem' }}
      />

      <div className='next-prev-button-container '>
        <PrevButton label='Previous' onClick={handlePrev} />
        <CommonButton
          label='Submit'
          onClick={() => setShowSubmitModel(true)}
          disabled={isButtonDisabled()}
        />
      </div>

      {showAddQuestionModal && (
        <AddQuestionModel
          setShowAddQuestionModal={setShowAddQuestionModal}
          showAddQuestionModal={showAddQuestionModal}
          courseId={feedbackId}
          refreshData={getQuestions}
        />
      )}
      {showImportQuestionsModal && (
        <ImportQuestionsModal
          setShowImportQuestionsModal={setShowImportQuestionsModal}
          showImportQuestionsModal={showImportQuestionsModal}
          courseId={feedbackId}
          refreshData={getQuestions}
        />
      )}
      {
        <SubmitModal
          showSubmitModel={showSubmitModel}
          setShowSubmitModel={setShowSubmitModel}
          onFinish={onNotifyClicked}
        />
      }
      {
        <EmailSentModal
          showEmailSentModal={showEmailSentModal}
          setShowEmailSentModal={setShowEmailSentModal}
        />
      }
    </div>
  );
};
export default AddQuestions;

const SubmitModal = (props) => {
  const { showSubmitModel, setShowSubmitModel, onFinish } = props;
  const [selectedNotifyOption, setSelectedNotifyOption] = useState('none');
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    async function fetchUsersData() {
      const userResponse = await getAllUsers();
      const usersArr = userResponse?.result.map((each) => {
        return { label: each?.name, value: each?._id };
      });
      setUsersList(usersArr);
    }
    fetchUsersData();
  }, []);

  const optionsWithDisabled = [
    { label: 'All', value: 'all' },
    { label: 'Selected', value: 'selected' },
    { label: 'None', value: 'none' },
  ];

  const handleRadioChange = ({ target: { value } }) => {
    setSelectedNotifyOption(value);
  };

  const minimumDate = new Date(); // set the minimum date to today

  const disabledDate = (current) => {
    // disable dates before the minimum date
    return current && current < minimumDate;
  };

  return (
    <Modal
      title={<h4 className='modal-title'>Final submit</h4>}
      open={showSubmitModel}
      bodyStyle={{ borderRadius: 10 }}
      style={{ borderRadius: '100%' }}
      footer={false}
      maskStyle={{ backgroundColor: 'rgba(30, 34, 37, 0.8)' }}
      onCancel={() => {
        setShowSubmitModel(false);
      }}
    >
      <Form onFinish={onFinish} layout='vertical' requiredMark={false}>
        <Form.Item
          label='Feedback Deadline'
          name='feedbackDeadline'
          rules={[
            { required: true, message: 'Feedback Deadline is required*' },
          ]}
        >
          <DatePicker
            className='input-style'
            style={{ width: '100%' }}
            showTime={{ format: 'HH:mm' }}
            format='YYYY-MM-DD HH:mm'
            disabledDate={disabledDate}
          />
        </Form.Item>

        <Form.Item label='Notify Users' name='notifyUsersOptions'>
          <Radio.Group
            options={optionsWithDisabled}
            onChange={handleRadioChange}
            optionType='button'
            buttonStyle='solid'
            defaultValue={'none'}
          />
        </Form.Item>

        <Form.Item label='Select Users' name='selectedUsers'>
          <Select
            mode='multiple'
            style={{
              width: '100%',
            }}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            virtual
            disabled={selectedNotifyOption !== 'selected'}
            placeholder='Notify users'
            options={usersList}
            maxTagCount={'responsive'}
          />
        </Form.Item>

        <div className='flex justify-end mt-2 gap-3'>
          <button
            className='add-questions-button'
            style={{ width: '7.3rem', fontWeight: 600 }}
          >
            Notify
          </button>
        </div>
      </Form>
    </Modal>
  );
};

