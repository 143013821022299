/* eslint-disable new-cap */
import { Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import ImportQuestionsFileModal from './ImportQuestionsFileModal';
import AddEditQuestionModel from './AddEditQuestionModel';
import { deleteQuestionById, getExamById } from '../../../apicalls/exams';

import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import './styles.css';

import {
  DeleteOutlined,
  EditOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import { decryptFunction } from '../../../utils/commonFunctions/commonFunctions';
import {
  AddButton,
  NextButton,
  PrevButton,
} from '../../../components/customButtons/customButtons';

const AddEditQuestions = (props) => {
  const { examId, current, setCurrent, setAssessmentData } = props;
  const dispatch = useDispatch();
  const [showAddEditQuestionModal, setShowAddEditQuestionModal] =
    useState(false);
  const [showImportQuestionsModal, setShowImportQuestionsModal] =
    useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [examData, setExamData] = useState();

  const getExamData = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getExamById({
        examId: examId,
      });

      dispatch(HideLoading());
      if (response.status.success) {
        const decryptedResponse = decryptFunction(response?.result);
        setExamData(decryptedResponse);
        setAssessmentData(decryptedResponse);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };
  const deleteQuestion = async (id) => {
    try {
      const response = await deleteQuestionById(id);
      if (response.status.success) {
        message.success(response.status.message);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (examId) {
      getExamData();
    }
  }, []);

  const isButtonDisabled = () => {
    return examData?.questions?.length > 0 ? false : true;
  };

  const handlePrev = () => {
    setCurrent(current - 1);
  };
  const handleNext = () => {
    setCurrent(current + 1);
  };

  const questionsColumns = [
    {
      title: 'Question',
      dataIndex: 'name',
    },
    { title: 'Weightage', dataIndex: 'weightage' },

    {
      title: 'Options',
      dataIndex: 'options',
      render: (text, record) => {
        return Object.keys(record.options).map((key,index) => {
          return (
            <div key={index}>
              {key} : {record.options[key]}
            </div>
          );
        });
      },
    },
    {
      title: 'Correct Option',
      dataIndex: 'correctOption',
      render: (text, record) => {
        return ` ${record.correctOption} : ${
          record.options[record.correctOption]
        }`;
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (text, record) => (
        <div className='flex gap-2'>
          <DeleteOutlined
            onClick={() => {
              deleteQuestion(record._id);
            }}
          />
          <EditOutlined
            onClick={() => {
              setSelectedQuestion(record);
              setShowAddEditQuestionModal(true);
            }}
          />
        </div>
      ),
    },
  ];

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };
  return (
    <div className='flex flex-col'>
      <div className='flex justify-end' style={{ marginBottom: 20, flex: 1 }}>
        <AddButton
          label='Add Question'
          onClick={() => setShowAddEditQuestionModal(true)}
        />
        <div className='import-button-container'>
          <button
            className='add-questions-button'
            type='button'
            onClick={() => setShowImportQuestionsModal(true)}
          >
            <ImportOutlined style={{ marginRight: 8, fontSize: '1.2rem' }} />
            <span>Import Questions</span>
          </button>
        </div>
      </div>

      <Table
        columns={questionsColumns}
        dataSource={examData?.questions || []}
        rowClassName={rowClassName}
        style={{ height: '100%', marginBottom: '5rem' }}
      />

      <div className='next-prev-button-container '>
        <PrevButton label='Previous' onClick={handlePrev} />
        <NextButton
          label='Next'
          onClick={handleNext}
          disabled={isButtonDisabled()}
        />
      </div>

      {showAddEditQuestionModal && (
        <AddEditQuestionModel
          setShowAddEditQuestionModal={setShowAddEditQuestionModal}
          showAddEditQuestionModal={showAddEditQuestionModal}
          examId={examId}
          refreshData={getExamData}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
        />
      )}
      {showImportQuestionsModal && (
        <ImportQuestionsFileModal
          setShowImportQuestionsModal={setShowImportQuestionsModal}
          showImportQuestionsModal={showImportQuestionsModal}
          examId={examId}
          refreshData={getExamData}
        />
      )}
    </div>
  );
};
export default AddEditQuestions;

