/* eslint-disable new-cap */
import { Radio, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getExamById } from '../../../apicalls/exams';
import { addReport } from '../../../apicalls/reports';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import Instructions from './Instructions';
import convertSecondsToHHMMSS from '../../../utils/convertSecondsToHHMMSS';
import { IMAGES } from '../../../assets/images';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './style.css';
import { decryptFunction } from '../../../utils/commonFunctions/commonFunctions';

function WriteExam() {
  const [examData, setExamData] = React.useState(null);
  const [questions = [], setQuestions] = React.useState([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = React.useState(0);
  const [selectedOptions, setSelectedOptions] = React.useState({});
  const [result = {}, setResult] = React.useState({});
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [view, setView] = useState('instructions');
  const [secondsLeft = 0, setSecondsLeft] = useState(0);
  const [timeUp, setTimeUp] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const { user } = useSelector((state) => state.users);

  // const goFullScreen = () => {
  //   if (document.documentElement.requestFullscreen) {
  //     document.documentElement.requestFullscreen(); // W3C spec
  //   } else if (document.documentElement.mozRequestFullScreen) {
  //     document.documentElement.mozRequestFullScreen(); // Firefox
  //   } else if (document.documentElement.webkitRequestFullscreen) {
  //     document.documentElement.webkitRequestFullscreen(); // Safari
  //   } else if (document.documentElement.msRequestFullscreen) {
  //     document.documentElement.msRequestFullscreen(); // IE/Edge
  //   }
  // };

  // useEffect(() => {
  //   const handleFullScreenChange = () => {
  //     if (!document.fullscreenElement) {
  //       // User is trying to exit full-screen mode
  //       window.alert(
  //         "You have attempted to exit full-screen mode. Your exam will be submitted."
  //       );
  //       goFullScreen();
  //     }
  //   };
  //   document.addEventListener("fullscreenchange", handleFullScreenChange);
  //   return () => {
  //     document.removeEventListener("fullscreenchange", handleFullScreenChange);
  //   };
  // }, []);

  // useEffect(() => {
  //   goFullScreen();
  // }, []);

  const getExamData = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getExamById({
        examId: params.id,
      });
      dispatch(HideLoading());
      if (response.status.success) {
        const decryptedResponse = decryptFunction(response?.result);
        setQuestions(decryptedResponse.questions);
        setExamData(decryptedResponse);

        setSecondsLeft(decryptedResponse?.duration * 60);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };
  const calculateResult = async () => {
    try {
      const correctAnswers = [];
      const wrongAnswers = [];

      questions.forEach((question, index) => {
        if (question.correctOption === selectedOptions[index]) {
          correctAnswers.push(question);
        } else {
          wrongAnswers.push(question);
        }
      });

      let verdict = 'Pass';

      const obtainedMarks = correctAnswers?.reduce(
        (acc, curr) => acc + curr.weightage,
        0
      );

      if (obtainedMarks < examData.passingMarks) {
        verdict = 'Fail';
      }

      const tempResult = {
        correctAnswers,
        wrongAnswers,
        verdict,
        obtainedMarks,
      };

      setResult(tempResult);
      dispatch(ShowLoading());
      const response = await addReport({
        exam: params.id,
        result: tempResult,
        user: user._id,
      });
      dispatch(HideLoading());
      if (response.status.success) {
        setView('result');
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const startTimer = () => {
    let totalSeconds = examData.duration * 60;
    const intervalId = setInterval(() => {
      if (totalSeconds > 0) {
        totalSeconds = totalSeconds - 1;
        setSecondsLeft(totalSeconds);
      } else {
        setTimeUp(true);
      }
    }, 1000);

    setIntervalId(intervalId);
  };

  useEffect(() => {
    if (timeUp && view === 'questions') {
      clearInterval(intervalId);
      calculateResult();
    }
  }, [timeUp]);

  useEffect(() => {
    if (params.id) {
      getExamData();
    }
  }, []);
  return (
    examData && (
      <div className='mt-2'>
        {/* <div className="divider"></div> */}
        <div className='flex justify-between'>
          <p className='assessment-title'>{examData?.name}</p>

          <div className='timer '>
            <img
              src={IMAGES.timerIcon}
              alt='timer logo'
              style={{ height: '1.3rem', marginRight: 10 }}
            ></img>
            <span className='timer-text'>
              {convertSecondsToHHMMSS(secondsLeft)}
            </span>
          </div>
        </div>
        <div className='divider'></div>
        {view === 'questions' && <div style={{ marginBottom: '2rem' }}></div>}

        {view === 'instructions' && (
          <Instructions
            examData={examData}
            setView={setView}
            startTimer={startTimer}
          />
        )}

        {view === 'questions' && (
          <div className='flex flex-col gap-2'>
            <div className='flex justify-between'>
              <h1 className='question-text'>
                {selectedQuestionIndex + 1} :{' '}
                {questions[selectedQuestionIndex].name}
              </h1>
            </div>

            <div className='flex flex-col gap-2'>
              {Object.keys(questions[selectedQuestionIndex].options).map(
                (option, index) => {
                  return (
                    <Radio
                      key={option}
                      checked={
                        selectedOptions[selectedQuestionIndex] === option
                          ? true
                          : false
                      }
                      onClick={() => {
                        setSelectedOptions({
                          ...selectedOptions,
                          [selectedQuestionIndex]: option,
                        });
                      }}
                    >
                      <h1 className='options-text'>
                        <span style={{ fontWeight: 600 }}>{option} : </span>
                        {questions[selectedQuestionIndex].options[option]}
                      </h1>
                    </Radio>
                  );
                }
              )}
            </div>

            <div className='button-container'>
              {selectedQuestionIndex > 0 && (
                <button
                  className='next-prev-button prev-button'
                  onClick={() => {
                    setSelectedQuestionIndex(selectedQuestionIndex - 1);
                  }}
                >
                  <LeftOutlined style={{ marginRight: 10 }} />
                  <span>Previous</span>
                </button>
              )}

              {selectedQuestionIndex < questions.length - 1 && (
                <button
                  className='next-prev-button next-button'
                  onClick={() => {
                    setSelectedQuestionIndex(selectedQuestionIndex + 1);
                  }}
                >
                  <span> Next</span>

                  <RightOutlined style={{ marginLeft: 10 }} />
                </button>
              )}

              {selectedQuestionIndex === questions.length - 1 && (
                <button
                  className='next-prev-button next-button'
                  onClick={() => {
                    clearInterval(intervalId);
                    setTimeUp(true);
                  }}
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        )}

        {view === 'result' && (
          <div className='result_container'>
            <div className='flex flex-col gap-2'>
              <div className='marks'>
                <h1 className='text-2xl'>
                  {result.verdict.toLowerCase() === 'pass'
                    ? 'Congratulations!'
                    : 'Sorry, better luck next time'}
                </h1>
                <h2 className='text-md'>
                  {result.verdict.toLowerCase() === 'pass'
                    ? 'You have passed the test!'
                    : 'You have failed the test'}
                </h2>
                <h2 className='text-md'>Total Marks : {examData.totalMarks}</h2>
                <h2 className='text-md'>
                  Obtained Marks :{result.obtainedMarks}
                </h2>

                <h2 className='text-md'>
                  Wrong Answers : {result.wrongAnswers.length}
                </h2>
                <h2 className='text-md'>
                  Passing Marks : {examData.passingMarks}
                </h2>
                <h2 className='text-md'>VERDICT :{result.verdict}</h2>

                <div className='flex gap-2 mt-2'>
                  <button
                    className='Global_Primary_Button'
                    onClick={() => {
                      setView('review');
                    }}
                  >
                    Review Answers
                  </button>
                </div>
              </div>
            </div>
            <div className='lottie-animation'>
              {result.verdict === 'Pass' && (
                <lottie-player
                  src='https://assets5.lottiefiles.com/packages/lf20_kfl4ksd9.json'
                  background='transparent'
                  speed='1'
                  loop
                  autoplay
                ></lottie-player>
              )}

              {result.verdict === 'Fail' && (
                <lottie-player
                  src='https://assets4.lottiefiles.com/packages/lf20_reg7q42p.json'
                  background='transparent'
                  speed='1'
                  loop
                  autoplay
                ></lottie-player>
              )}
            </div>
          </div>
        )}

        {view === 'review' && (
          <div className='flex flex-col gap-2'>
            {questions.map((question, index) => {
              const isCorrect =
                question.correctOption === selectedOptions[index];

              return (
                <div
                  key={question._id}
                  className={`
                  flex flex-col gap-1 p-2 ${
                    isCorrect ? 'success_background' : 'failed_background'
                  }
                `}
                >
                  <h1 className='question-text' style={{ marginBottom: 10 }}>
                    {index + 1} : {question.name}
                  </h1>

                  {selectedOptions[index] ? (
                    <h1 className='question-text'>
                      You Answered :{'  '}
                      <span style={{ fontWeight: 600 }}>
                        {selectedOptions[index]}.
                      </span>{' '}
                      {question.options[selectedOptions[index]]}
                    </h1>
                  ) : (
                    <h1 className='question-text'>
                      You have not answered this question
                    </h1>
                  )}

                  {!isCorrect && (
                    <h1 className='question-text'>
                      Correct Answer :{'  '}
                      <span style={{ fontWeight: 600 }}>
                        {question.correctOption}.
                      </span>{' '}
                      {question.options[question.correctOption]}
                    </h1>
                  )}
                </div>
              );
            })}

            <div className='flex justify-end '>
              <button
                className='next-prev-button prev-button'
                onClick={() => {
                  navigate('/');
                }}
                style={{ margin: 0 }}
              >
                <LeftOutlined style={{ marginRight: 10 }} />
                <span>Back</span>
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );
}

export default WriteExam;
