/* eslint-disable new-cap */
import React, { useEffect } from 'react';
import { message, Table, Tag } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import PageTitle from '../../../components/PageTitle';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import { getAllReportsByUser } from '../../../apicalls/reports';


function UserReports() {
  const [reportsData, setReportsData] = React.useState([]);
  const dispatch = useDispatch();
  const columns = [
    {
      title: 'Assessment Name',
      dataIndex: 'assessmentName',
      render: (text, record) => <>{record?.exam?.name}</>,
    },
    {
      title: 'Date & Time',
      dataIndex: 'date',
      render: (text, record) => (
        <>{moment(record.createdAt).format('DD-MM-YYYY - hh:mm:ss')}</>
      ),
    },
    {
      title: 'Total Marks',
      dataIndex: 'totalQuestions',
      render: (text, record) => <>{record?.exam?.totalMarks}</>,
    },
    {
      title: 'Passing Marks',
      dataIndex: 'correctAnswers',
      render: (text, record) => <>{record?.exam?.passingMarks}</>,
    },
    {
      title: 'Obtained Marks',
      dataIndex: 'correctAnswers',
      render: (text, record) => <>{record?.result?.correctAnswers?.length}</>,
    },
    {
      title: 'Verdict',
      dataIndex: 'verdict',
      render: (text, record) => (
        <Tag
          color={
            record.result.verdict.toLowerCase() === 'fail' ? 'red' : 'green'
          }
        >
          {record.result.verdict}
        </Tag>
      ),
    },
  ];

  const getData = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getAllReportsByUser();
      if (response.status.success) {
        setReportsData(response.result);
      } else {
        message.error(response.status.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageTitle title='Reports' />
      <div className='divider'></div>
      <Table columns={columns} dataSource={reportsData} />
    </div>
  );
}

export default UserReports;
