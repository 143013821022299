import { message } from 'antd';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const refreshToken = localStorage.getItem('refreshToken');

const getRefreshToken = async () => {
  const body = {
    refreshToken: refreshToken,
  };
  try {
    const response = await axiosInstance.post(
      `/auth/google/refresh-token`,
      body
    );
    if (response.data) {
      // eslint-disable-next-line camelcase
      const { id_token, refresh_token } = response?.data;
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.setItem('token', id_token);
      localStorage.setItem('refreshToken', refresh_token);
    }
  } catch (e) {
    message.error(e.message);
  }
};

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    config.baseURL = BASE_URL;
    return config;
  },
  (error) => {
    // eslint-disable-next-line no-console
    console.log('interceptor error', error)
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error?.config;
    const originalRequesUrl = originalRequest?.url;
    if (
      error?.response?.status === 401 &&
      !originalRequesUrl.includes('/login') &&
      refreshToken
    ) {
      // Handle Refresh Token
      getRefreshToken();
    }
  }
);

export default axiosInstance;
