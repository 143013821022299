/* eslint-disable react/prop-types */
import React from 'react';
import { Col, Row, Empty } from 'antd';
import Card from '../../../components/Card';

const ExpiredAssesments = ({ AllExpiredAssesments }) => {
  return (
    <div>
      <Row gutter={[16, 16]}>
        {AllExpiredAssesments?.length ? (
          AllExpiredAssesments?.map((item, index) => (
            <Col key={index}>
              <Card
                item={item}
                category={item.category}
                duration={item.duration}
                startExamDate={item.startExamDate}
                isInProgress={item.startExamDate ? false : true}
                endExamDate={item.endExamDate}
                buttonName='Reopen'
                isDisabled={true}
              />
            </Col>
          ))
        ) :<Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col
          className='gutter-row'
          span={24}
          style={{width:'100vw'}}
        >
           <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
      </Row>}
      </Row>
    </div>
  );
};

export default ExpiredAssesments;
