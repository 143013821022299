/* eslint-disable new-cap */
/* eslint-disable no-console */
/* eslint-disable guard-for-in */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Radio, Space, Input, Form, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllFeedbackQuestionsAction } from '../../redux/feedBackSlice';
import PageTitle from '../../components/PageTitle';
import Divider from '../../components/Divider';
import { CommonButton } from '../../components/customButtons/customButtons';
import { submitFeedbackReport } from '../../apicalls/feedback';
import { HideLoading, ShowLoading } from '../../redux/loaderSlice';

const GiveFeedback = () => {
  const { id } = useParams();
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const [result2, setResult2] = useState([]);
  const navigate = useNavigate();
  const state = useSelector((state) => state.feedback);

  useEffect(() => {
    dispatch(getAllFeedbackQuestionsAction(id));
    dispatch(ShowLoading());
    setTimeout(() => {
      dispatch(HideLoading());
    }, 300);
  }, []);
  // eslint-disable-next-line no-console
  const handleGetValue = (value, question) => {
    if (result2?.some((e) => e.question === question)) {
      for (const item in result2) {
        if (result2[item].question === question) {
          result2[item].rating = value;
        }
      }
    } else {
      setResult2((prevState) => [
        ...prevState,
        { question: question, rating: value },
      ]);
    }
  };
  const getAverageRating = () => {
    const sumOfAllRating = result2.reduce(function (acc, obj) {
      return acc + obj?.rating;
    }, 0);
    const averageOfRating = sumOfAllRating / result2.length;
    return averageOfRating;
  };

  const onFinish = async () => {
    if (state?.questions?.length !== result2.length) {
      message.error('Please fill all the filed');
      return;
    }

    try {
      dispatch(ShowLoading());
      const payload = {};
      payload.course_id = id;
      payload.description = description;
      payload.result = result2;
      payload.rating = getAverageRating();
      const response = await submitFeedbackReport(payload);
      if (response?.status?.success) {
        message.success('Feedback Submitted Successfully');
        dispatch(HideLoading());
        navigate('/user/feedback');
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error?.message);
    }
  };
  return (
    <div>
      <PageTitle title='Course Feedback' />
      <Divider />
      <Form autoComplete='off' onFinish={onFinish}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          {state?.questions?.map((item, index) => {
            return (
              <Col
                style={{ marginBottom: 30 }}
                key={index}
                className='gutter-row'
                span={24}
              >
                <div style={{ marginBottom: 5 }}>
                  {index + 1}. {item.name}
                </div>
                <div style={{ paddingLeft: '1rem' }}>
                  <RadioGroup
                    handleGetValue={handleGetValue}
                    question={item.name}
                  />
                </div>
              </Col>
            );
          })}
        </Row>
        <Row
          style={{ marginTop: '1.5rem' }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col className='gutter-row' span={24}>
            <Form.Item
              name='description'
              rules={[{ required: true, message: 'Missing Description' }]}
            >
              <Input
                size='large'
                style={{ height: '5rem' }}
                showCount
                maxLength={100}
                placeholder='Please add any additional comment'
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            className='gutter-row'
            span={24}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <CommonButton label='submit' type='submit' />
          </Col>
        </Row>
      </Form>
    </div>
  );
};
//* Radio Group Button
export const RadioGroup = ({ handleGetValue, question }) => {
  const [value, setValue] = useState(null);
  const onChange = (e) => {
    setValue(e.target.value);
    handleGetValue(e.target.value, question);
  };

  return (
    <Radio.Group onChange={onChange} value={value}>
      <Space direction='horizontal'>
        <Radio value={1}> 1 </Radio>
        <Radio value={2}> 2 </Radio>
        <Radio value={3}> 3 </Radio>
        <Radio value={4}> 4 </Radio>
        <Radio value={5}> 5 </Radio>
      </Space>
    </Radio.Group>
  );
};

export default GiveFeedback;

