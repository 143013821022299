import React from 'react';
import { Col, Empty, Row } from 'antd';
import { useSelector } from 'react-redux';
import PageTitle from '../../../components/PageTitle';
import Card from '../../../components/Card';

import './home.css';
import Admin from '../../admin/Home/Admin';
import useExamData from './hooks/useExamData';
function Home() {
  const { exams } = useExamData();
  const { user } = useSelector((state) => state.users);


  function renderUIBasedOnWhoIsLogin() {
    if (!user?.isAdmin) {
      return (
        <div>
          <PageTitle title={`Hi Welcome back, ${user?.name}`} />
          <div className='divider'></div>
          <h2>Active Assessment</h2>
          <Row gutter={[16, 16]}>
            {exams?.all_active_exams?.length > 0 ? (
              exams?.all_active_exams?.map((item, index) => (
                <Col key={index}>
                  <Card
                    item={item}
                    category={item.category}
                    duration={item.duration}
                    startExamDate={item.startExamDate}
                    isInProgress={item.startExamDate ? false : true}
                    endExamDate={item.endExamDate}
                    buttonName='Start Test'
                    isDisabled={item.startExamDate ? false : true}
                  />
                </Col>
              ))
            ) : (
              <Col className='no-data-found'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            )}
          </Row>
          <div className='divider'></div>
          <h2>Attempted Assessment</h2>
          <Row gutter={[16, 16]}>
            {exams?.attempted_exams?.length > 0 ? (
              exams?.attempted_exams?.map((item, index) => (
                <Col key={index}>
                  <Card
                    item={item.exam}
                    category={item.exam.category}
                    duration={item.exam.duration}
                    attemptedDate={item.createdAt}
                    result={item?.result}
                    buttonName='Review Test'
                    isDisabled={true}
                  />
                </Col>
              ))
            ) : (
              <Col className='no-data-found'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            )}
          </Row>
          <div className='divider'></div>
          <h2>Expired Assessment</h2>
          <Row gutter={[16, 16]}>
            {exams?.all_expired_exams?.length > 0 ? (
              exams?.all_expired_exams?.map((item, index) => (
                <Col key={index}>
                  <Card
                    item={item}
                    category={item.category}
                    duration={item.duration}
                    endExamDate={item.endExamDate}
                    buttonName='Expired Test'
                    isDisabled={true}
                    isExpired={true}
                  />
                </Col>
              ))
            ) : (
              <Col className='no-data-found'>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            )}
          </Row>
        </div>
      );
    } else {
      return <Admin />;
    }
  }

  return <>{renderUIBasedOnWhoIsLogin()}</>;
}

export default Home;
