import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    user: null,
    profileImageUrl: '',
  },
  reducers: {
    SetUser: (state, action) => {
      state.user = action.payload;
    },
    setUserImageUrl: (state, action) => {
      state.profileImageUrl = action.payload;
    },
  },
});

export const { SetUser, setUserImageUrl } = usersSlice.actions;
export default usersSlice.reducer;

