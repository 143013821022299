/* eslint-disable new-cap */
import { Col, Form, Input, message, Row } from 'antd';
import React, { useEffect } from 'react';
import { getExamById } from '../../../apicalls/exams';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';

import { decryptFunction } from '../../../utils/commonFunctions/commonFunctions';
import {
  CancelButton,
  NextButton,
} from '../../../components/customButtons/customButtons';

function AddEditAssessment({ onFinish }) {
  const [form] = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [examData, setExamData] = React.useState(null);

  const params = useParams();

  const getExamData = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getExamById({
        examId: params.id,
      });

      dispatch(HideLoading());
      if (response.status.success) {
        const decryptedResponse = decryptFunction(response?.result);
        const data = { ...decryptedResponse };
        const modifiedData = {
          ...data,
          examDate: [
            moment(data.startExamDate, 'YYYY-MM-DD HH:mm'),
            moment(data.endExamDate, 'YYYY-MM-DD HH:mm'),
          ],
        };
        setExamData(modifiedData);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getExamData();
    }
  }, []);

 

  return (
    <div style={{ height: '63vh' }}>
      {(examData || !params.id) && (
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
          initialValues={examData}
          requiredMark={false}
        >
          <Row gutter={[10, 10]}>
            <Col span={8}>
              <Form.Item
                style={{ fontWeight: 500 }}
                label='Exam Name'
                name='name'
                rules={[{ required: true, message: 'Exam Name is required*' }]}
              >
                <Input
                  type='text'
                  className='input-style'
                  placeholder='Enter exam name'
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label='Assessment Duration(Minutes)'
                name='duration'
                style={{ fontWeight: 500 }}
                rules={[
                  { required: true, message: 'Duration is required*' },
                  {
                    type: 'number',
                    min: 0,
                    message: 'Number must be positive!',
                    transform: (value) => {
                      if (value) {
                        return Number(value);
                      }
                    },
                  },
                ]}
              >
                <Input
                  type='number'
                  className='input-style'
                  placeholder='Enter duration in minutes'
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label='Category'
                name='category'
                rules={[{ required: true, message: 'Category is required*' }]}
              >
                <Input
                  type='text'
                  className='input-style'
                  placeholder='Enter category'
                />
              </Form.Item>
            </Col>
          </Row>
          <div className='next-prev-button-container'>
            <CancelButton
              label='Cancel'
              onClick={() => navigate('/admin/assessment')}
            />

            <NextButton label='Next' type='submit' />
          </div>
        </Form>
      )}
    </div>
  );
}

export default AddEditAssessment;
