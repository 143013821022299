/* eslint-disable new-cap */
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ViewFeedBack from './pages/Feedback/ViewFeedBack';
import GiveFeedback from './pages/Feedback/GiveFeedback';
import Login from './pages/common/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './pages/common/Home';
import Assessment from './pages/admin/Assessment';
import Loader from './components/Loader';
import WriteAssessment from './pages/user/WriteAssessment';
import UserReports from './pages/user/UserReports';
import AdminReports from './pages/admin/AdminReports';
import UserProfile from './pages/user/Profile';
import MultiStepForm from './pages/admin/Assessment/MultiStepForm';
import PageNotFound from './pages/common/PageNotFound';
import CreateAdmin from './pages/superAdmin/CreateAdmin';
import PreviewAssessment from './pages/admin/Preview';
import FeedBack from './pages/Feedback';
import Steps from './pages/Feedback/Steps';

import './stylesheets/theme.css';
import './stylesheets/alignments.css';
import './stylesheets/textelements.css';
import './stylesheets/custom-components.css';
import './stylesheets/layout.css';


function App() {
  const { loading } = useSelector((state) => state.loader);

  return (
    <>
      {loading && <Loader />}
      <BrowserRouter>
        <Routes>
          {/* Common Routes */}
          <Route path='login' element={<Login />} />

          {/* User Routes */}
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path='/user/write-assessment/:id'
            element={
              <ProtectedRoute>
                <WriteAssessment />
              </ProtectedRoute>
            }
          />
          <Route
            path='/user/reports'
            element={
              <ProtectedRoute>
                <UserReports />
              </ProtectedRoute>
            }
          />
          <Route
            path='/user/feedback'
            element={
              <ProtectedRoute>
                <FeedBack />
              </ProtectedRoute>
            }
          />
          <Route
            path='/user/feedback/give/:id'
            element={
              <ProtectedRoute>
                <GiveFeedback />
              </ProtectedRoute>
            }
          />
          <Route
            path='/user/profile'
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          {/* Admin Routes */}
          <Route
            path='/admin/assessment'
            element={
              <ProtectedRoute>
                <Assessment />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/assessment/add'
            element={
              <ProtectedRoute>
                <MultiStepForm />
              </ProtectedRoute>
            }
          />

          <Route
            path='/admin/assessment/edit/:id'
            element={
              <ProtectedRoute>
                <MultiStepForm />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/reports'
            element={
              <ProtectedRoute>
                <AdminReports />
              </ProtectedRoute>
            }
          />

          <Route
            path='/admin/feedback'
            element={
              <ProtectedRoute>
                <FeedBack />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/feedback/add'
            element={
              <ProtectedRoute>
                <Steps />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/feedback/view/:id'
            element={
              <ProtectedRoute>
                <ViewFeedBack />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/profile'
            element={
              <ProtectedRoute>
                <UserProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/preview/:id'
            element={
              <ProtectedRoute>
                <PreviewAssessment />
              </ProtectedRoute>
            }
          />
          <Route
            path='/admin/create-admin'
            element={
              <ProtectedRoute>
                <CreateAdmin />
              </ProtectedRoute>
            }
          />

          <Route
            path='/*'
            element={
              <ProtectedRoute>
                <PageNotFound />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
