/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import ActiveAssesments from './ActiveAssesments';
import ExpiredAssesments from './ExpiredAssesments';
import { useDispatch, useSelector } from 'react-redux';
import { ShowLoading, HideLoading } from '../../../redux/loaderSlice';
import { getAllExams } from '../../../apicalls/exams';

import './styles.css';
import PageTitle from '../../../components/PageTitle';
import Divider from '../../../components/Divider';

const Admin = () => {
  const [activeAssessment, setActiveAssessment] = useState(true);
  const [expiredAssessment, setExpiredAssessment] = useState(false);
  const { user } = useSelector((state) => state.users);
  const [exams, setExams] = React.useState([]);
  const dispatch = useDispatch();

  const getExams = async () => {
    try {
      dispatch(ShowLoading());
      setTimeout(async () => {
        const response = await getAllExams();
        if (response?.status?.success) {
          setExams(() => response.result);
        } else {
          message.error(response?.status?.message);
        }
        dispatch(HideLoading());
      }, 100);
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  useEffect(() => {
    getExams();
  }, []);
  return (
    <div>
      <PageTitle title={`Hi Welcome back, ${user?.name}`} />
      <Divider />
      <div className='button-wrapper divider'>
        <span
          className={activeAssessment ? 'active_button' : 'inactive_button'}
          onClick={() => {
            setActiveAssessment(true);
            setExpiredAssessment(false);
          }}
        >
          Active Assessment
        </span>
        <span
          className={expiredAssessment ? 'active_button' : 'inactive_button'}
          onClick={() => {
            setExpiredAssessment(true);
            setActiveAssessment(false);
          }}
        >
          Expired Assessment
        </span>
      </div>
      {activeAssessment && (
        <ActiveAssesments
          AllActiveAssesments={exams?.all_active_exams}
          exams={exams}
          setExams={setExams}
        />
      )}
      {expiredAssessment && (
        <ExpiredAssesments
          AllExpiredAssesments={exams?.all_expired_exams}
        ></ExpiredAssesments>
      )}
    </div>
  );
};

export default Admin;
