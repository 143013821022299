import React from 'react';
import { useSelector } from 'react-redux';
// import { Empty } from 'antd';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import Divider from '../../components/Divider';
import { AddButton } from '../../components/customButtons/customButtons';

import './styles.css';
import ListFeedBacks from './ListFeedBacks';

const FeedBack = () => {
  const { user } = useSelector((state) => state.users);
  const navigation = useNavigate()
  function checkUserType(){
    return !!(user.isAdmin||user.isSuperAdmin);
   }
 
  return (
    <div>
      <PageTitle title={`Hi Welcome back, ${user?.name}`} />
      <Divider />
      <div className='flex justify-between mt-2 items-end'>
        <PageTitle title='Courses' />
        {checkUserType()&&<AddButton
          label='Add Course'
          onClick={() => navigation('/admin/feedback/add')}
        />}
      </div>
      <div className='card-wrapper'>
        {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> */}
        <ListFeedBacks />
        
      </div>
    </div>
  );
};

export default FeedBack;
