import React from 'react';
import './radioButtonStyle.css';
import { COLORS } from '../../constants/colors';

const CustomRadioButton = (props) => {
  const { label, checked, onClick } = props;
  const borderColor = checked ? COLORS.primary_green : COLORS.text_grey;
  const textColor = checked ? COLORS.primary_white : COLORS.text_grey;
  const backgroundColor = checked ? COLORS.primary_green : COLORS.primary_white;
  return (
    <div
      onClick={onClick}
      className='custom-radio'
      style={{
        backgroundColor: backgroundColor,
        color: textColor,
        borderColor: borderColor,
      }}
    >
      {label}
    </div>
  );
};
export default CustomRadioButton;
