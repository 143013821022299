const COLORS = {
  primary_green: '#278357',
  secondary_green: '#26BF67',
  primary_grey: '#CCCCCC',
  primary_light_green: '#CFF9E2',
  primary_black: '#000000',
  primary_white: '#FFFFFF',
  primary_dark_green: ' #052010',
  alert_green: '#067652',
  alert_light_red: '#EC5B62',
  text_grey: '#858C89',
};
export { COLORS };
