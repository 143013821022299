/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import { STRINGS } from '../../../constants';
import { useDispatch } from 'react-redux';
import { setUserImageUrl } from '../../../redux/usersSlice';
import logo from '../../../assets/images/logo.png';
import googleLogo from '../../../assets/images/google-logo.png';
import { HideLoading } from '../../../redux/loaderSlice';

import './login.css';

function Login() {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/google`,
          {
            code,
          }
        );

        // eslint-disable-next-line camelcase
        const { refresh_token, id_token } = response?.data?.tokens;
        // eslint-disable-next-line camelcase
        const { img_url } = response?.data;
        dispatch(setUserImageUrl(img_url));
        localStorage.setItem('token', id_token);
        localStorage.setItem('refreshToken', refresh_token);

        if (location?.state) {
          navigate(location.state.pathname);
        } else navigate('/');
      } catch (error) {
        if (error.response.status === 422) {
          setErrorMessage(STRINGS.INVALID_DOMAIN_MESSAGE);
        } else {
          setErrorMessage(STRINGS.LOGIN_ERROR_MESSAGE);
        }
      }
    },
    onFailure: (error) => {
      // eslint-disable-next-line no-console
      console.log('Error : ', error);
    },
    client_id: process.env.REACT_APP_CLIENT_ID,
    flow: 'auth-code',
  });

  useEffect(() => {
    // dispatch(ShowLoading())
    dispatch(HideLoading());
    // setTimeout(()=>{
    //   dispatch(HideLoading())
    // },1000)
  }, []);
  return (
    <div className='main-container'>
      <div className='left-container'>
        <div style={{ paddingLeft: '4.188rem', paddingTop: '2.938rem' }}>
          <img src={logo} alt='logo' width={'134.91px'} height={'23.03px'} />
        </div>
      </div>
      <div className='right-container'>
        <div className='form-container'>
          <p className='welcome-text'>Welcome Back!</p>
          <p className='assess-skill-text'>{STRINGS.LOGIN_TEXT_DESCRIPTION}</p>
          <div className='google-button' onClick={googleLogin}>
            <img
              src={googleLogo}
              alt='Google logo'
              style={{ height: '35px', marginRight: '10px' }}
            />
            <span className='sign-in-google-text'>Sign in with Google</span>
          </div>
          {errorMessage && <div className='error-message'>{errorMessage}</div>}
        </div>
      </div>
    </div>
  );
}

export default Login;

