/* eslint-disable new-cap */
import { Form, message, Modal, Select } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { addQuestionToExam, editQuestionById } from '../../../apicalls/exams';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import './styles.css';

function AddEditQuestionModel({
  showAddEditQuestionModal,
  setShowAddEditQuestionModal,
  refreshData,
  examId,
  selectedQuestion,
  setSelectedQuestion,
}) {
  const dispatch = useDispatch();

  const onFinish = async (values) => {
    try {
      dispatch(ShowLoading());

      let response;
      if (selectedQuestion) {
        const editQuestionPayload = {
          questionId: selectedQuestion._id,
          name: values.name,
          correctOption: values.correctOption,
          options: {
            A: values.A,
            B: values.B,
            C: values.C,
            D: values.D,
          },
          weightage: values.questionWeightage,
        };

        response = await editQuestionById(editQuestionPayload);
      } else {
        const addQuestionsPayload = {
          examId: examId,
          questions: [
            {
              name: values.name,
              correctOption: values.correctOption,
              options: {
                A: values.A,
                B: values.B,
                C: values.C,
                D: values.D,
              },

              weightage: values.questionWeightage,
            },
          ],
        };
        response = await addQuestionToExam(addQuestionsPayload);
      }
      if (response.status.success) {
        message.success(response.status.message);
        refreshData();
        setShowAddEditQuestionModal(false);
      } else {
        message.error(response.error.message);
      }
      setSelectedQuestion(null);
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const validateNumberRange = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Weightage is required*'));
    }
  
    const numberValue = Number(value);
  
    if (isNaN(numberValue)) {
      return Promise.reject(new Error('Weightage must be a number'));
    }
  
    if (numberValue < 0) {
      return Promise.reject(new Error('Number must be positive'));
    }
    const decimalCount = (numberValue.toString().split('.')[1] || '').length;
    if (decimalCount > 3) {
      return Promise.reject(new Error('Number can have up to 3 decimal places'));
    }
    if (numberValue > 1000) {
      return Promise.reject(new Error('Enter a valid Weightage'));
    }
  
    return Promise.resolve();
  };
  return (
    <Modal
      title={
        <h4 className='modal-title'>
          {selectedQuestion ? 'Edit Question' : 'Add Question'}
        </h4>
      }
      open={showAddEditQuestionModal}
      maskStyle={{ backgroundColor: 'rgba(30, 34, 37, 0.8)' }}
      footer={false}
      onCancel={() => {
        setShowAddEditQuestionModal(false);
        setSelectedQuestion(null);
      }}
    >
      <Form
        onFinish={onFinish}
        layout='vertical'
        requiredMark={false}
        initialValues={{
          name: selectedQuestion?.name,
          A: selectedQuestion?.options?.A,
          B: selectedQuestion?.options?.B,
          C: selectedQuestion?.options?.C,
          D: selectedQuestion?.options?.D,
          correctOption: selectedQuestion?.correctOption,
          questionWeightage: selectedQuestion?.weightage,
        }}
      >
        <Form.Item name='name' label='Question'  rules={[{ required: true, message: 'Question is required*' }]}>
          <input className='input-box' type='text' />
        </Form.Item>
        <Form.Item
          name='questionWeightage'
          label='Question Weightage'
          rules={[
            { validator: validateNumberRange }
          ]}
        >
          <input className='input-box' type='number' />
        </Form.Item>
        <Form.Item
          name='correctOption'
          label='Correct Option'
          rules={[{ required: true, message: 'Correct Option is required*' }]}
        >
          <Select
            style={{ marginBottom: 20 }}
            placeholder='Choose the correct option'
            options={[
              { label: 'A', value: 'A' },
              { label: 'B', value: 'B' },
              { label: 'C', value: 'C' },
              { label: 'D', value: 'D' },
            ]}
          />
        </Form.Item>

        <div className='flex justify-between'>
          <Form.Item
            name='A'
            label='Option A'
            rules={[{ required: true, message: 'Option A is required*' }]}
          >
            <input className='input-box' type='text' />
          </Form.Item>
          <Form.Item
            name='B'
            label='Option B'
            rules={[{ required: true, message: 'Option B is required*' }]}
          >
            <input className='input-box' type='text' />
          </Form.Item>
        </div>
        <div className='flex justify-between'>
          <Form.Item
            name='C'
            label='Option C'
            rules={[{ required: true, message: 'Option C is required*' }]}
          >
            <input className='input-box' type='text' />
          </Form.Item>
          <Form.Item
            name='D'
            label='Option D'
            rules={[{ required: true, message: 'Option D is required*' }]}
          >
            <input className='input-box' type='text' />
          </Form.Item>
        </div>

        <div className='flex justify-end' style={{ marginTop: 20 }}>
          <button className='next-prev-button next-button'>Proceed</button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddEditQuestionModel;

