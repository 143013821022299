import React from 'react';
import { IMAGES } from '../../../assets/images';
import { STRINGS } from '../../../constants';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className='page-not-found-container'>
      <img
        className='page-not-found-image'
        src={IMAGES.notFoundErrorImage}
        alt='pageNotFoundImage'
      ></img>
      <p className='oops-text'>Ooops!</p>
      <p className='page-not-found-text'>{STRINGS.PAGE_NOT_FOUND_HINT}</p>
      <button
        className='back-to-home-button'
        onClick={() => {
          navigate('/');
        }}
      >
        <LeftOutlined style={{ marginRight: 10 }} />
        <span> Back to homepage</span>
      </button>
    </div>
  );
}
export default PageNotFound;
