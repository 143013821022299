/* eslint-disable new-cap */
import { message, Row, Table, Switch, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import PageTitle from '../../../components/PageTitle';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';

import './style.css';
import CustomSearch from '../../../components/Search/CustomSearch';
import {
  getAllUsersAndAdmins,
  updateAdminStatus,
} from '../../../apicalls/users';

function CreateAdmin() {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState([]);

  const dispatch = useDispatch();

  const getUserList = async (searchTerm) => {
    try {
      dispatch(ShowLoading());
      const response = await getAllUsersAndAdmins(searchTerm);

      dispatch(HideLoading());
      if (response.status.success) {
        setUsers(response?.result);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };
  useEffect(() => {
    getUserList();
  }, []);

  const handleToggle = async (isAdmin, userId) => {
    const payload = {
      isAdmin: isAdmin,
      userId: userId,
    };
    const response = await updateAdminStatus(payload);
    if (response?.status?.success) {
      message.success(response?.status?.message);
      getUserList(searchTerm);
    } else {
      message.error(response.error.message);
    }
  };

  const onSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (e.key === 'Enter' || value.length === 0) {
      getUserList(value);
    }
  };

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };

  const columns = [
    {
      title: 'Users',
      dataIndex: 'name',
      key: 'users',
    },
    {
      title: 'Admin',
      dataIndex: 'isAdmin',
      key: 'isAdmin',
      render: (isAdmin, record) => (
        <Switch
          checked={isAdmin}
          onChange={(value) => handleToggle(value, record?._id)}
        />
      ),
    },
  ];
  return (
    <div className='admin-panel-main-container'>
      <div className='flex justify-between mt-2 items-end'>
        <PageTitle title='Administrator Control Panel' />
      </div>
      <div className='divider'></div>
      <Row style={{ marginBottom: 20 }}>
        <Col span={10}>
          <CustomSearch placeholder={'Search Users'} onKeyUp={onSearch} />
        </Col>
      </Row>
      <Table columns={columns} dataSource={users} rowClassName={rowClassName} />
    </div>
  );
}

export default CreateAdmin;

