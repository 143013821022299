import {
  Form,
  Input,
  Modal,
  Radio,
  Select,
  DatePicker,
  Col,
  Row,
  message,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { getAllUsers } from '../../../apicalls/users';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { editExamById } from '../../../apicalls/exams';
import './styles.css';
import {
  CommonButton,
  OutlinedButton,
} from '../../../components/customButtons/customButtons';
import { formatNumber } from '../../../utils/commonFunctions/commonFunctions';

const FinishPage = (props) => {
  const navigate = useNavigate();
  const { examId, assessmentData, current, setCurrent } = props;
  const [showPublishAndFreezeModal, setPublishAndFreezeModal] = useState(false);
  const [passingMark, setPassingMark] = useState();

  const totalMarks = assessmentData?.questions?.reduce(
    (acc, curr) => acc + curr.weightage,
    0
  );

  const onFreezeClicked = async (value) => {
    const startDate = moment(value.assessmentDate[0]._d).format(
      'YYYY-MM-DD HH:mm'
    );
    const endDate = moment(value.assessmentDate[1]._d).format(
      'YYYY-MM-DD HH:mm'
    );

    const response = await editExamById({
      examId: examId,
      startExamDate: startDate,
      endExamDate: endDate,
      totalMarks: formatNumber(totalMarks),
      passingMarks: formatNumber(passingMark),
      notify_all: value.notifyUsersOptions === 'all' ? true : false,
      access_user_list: value.selectedUsers,
      freeze: true,
    });

    if (response.status.success) {
      message.success(response.status.message);
      navigate('/admin/assessment');
    } else {
      message.error(response.error.message);
    }
  };
  const onSubmitAndFreezeClick = () => {
    if (passingMark <= totalMarks) {
      setPublishAndFreezeModal(true);
    }
  };

  const handleSaveDraft = () => {
    navigate('/admin/assessment');
  };
  const handlePrevious = () => {
    setCurrent(current - 1);
  };

  const validatePassingMarks = (rule, value, callback) => {
    const decimalCount = value.toString().split('.')[1]?.length || 0;
    if (decimalCount > 3) {
      callback('Passing mark can have up to 3 decimal places');
    } else if (totalMarks && Number(value) > Number(totalMarks)) {
      callback('Passing mark should be less than or equal to Total Mark');
    } else {
      callback();
    }
  };
  return (
    <div style={{ height: '65vh' }}>
      <Form requiredMark={false} initialValues={assessmentData}>
        <div style={{ fontWeight: 500 }}>
          Total Marks :{formatNumber(totalMarks)}
        </div>
        <Row>
          <Col span={8}>
            <Form.Item
              style={{ fontWeight: 500 }}
              label='Passing weightage'
              name={'passingMarks'}
              rules={[
                { required: true, message: 'Passing Marks is required*' },
                {
                  type: 'number',
                  min: 0,
                  message: 'Number must be positive!',
                  transform: (value) => {
                    if (value) {
                      return Number(value);
                    }
                  },
                },
                {
                  validator: validatePassingMarks,
                },
              ]}
            >
              <Input
                style={{
                  width: '50%',
                }}
                onChange={(e) => setPassingMark(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <div className='next-prev-button-container'>
          <OutlinedButton label='Previous' onClick={handlePrevious} />
          <OutlinedButton label='Save Draft' onClick={handleSaveDraft} />
          <CommonButton label='Publish' onClick={onSubmitAndFreezeClick} />
        </div>
        {showPublishAndFreezeModal && (
          <PublishAndFreezeModal
            setPublishAndFreezeModal={setPublishAndFreezeModal}
            showPublishAndFreezeModal={showPublishAndFreezeModal}
            onFinish={onFreezeClicked}
          />
        )}
      </Form>
    </div>
  );
};

const PublishAndFreezeModal = (props) => {
  const { showPublishAndFreezeModal, setPublishAndFreezeModal, onFinish } =
    props;

  const { RangePicker } = DatePicker;
  const [selectedNotifyOption, setSelectedNotifyOption] = useState('none');
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    async function fetchUsersData() {
      const userResponse = await getAllUsers();
      const usersArr = userResponse?.result.map((each) => {
        return { label: each?.name, value: each?._id };
      });
      setUsersList(usersArr);
    }
    fetchUsersData();
  }, []);

  const optionsWithDisabled = [
    { label: 'All', value: 'all' },
    { label: 'Selected', value: 'selected' },
    { label: 'None', value: 'none' },
  ];

  const handleRadioChange = ({ target: { value } }) => {
    setSelectedNotifyOption(value);
  };

  const minimumDate = new Date(); // set the minimum date to today

  const disabledDate = (current) => {
    // disable dates before the minimum date
    return current && current < minimumDate;
  };

  return (
    <Modal
      title={<h4 className='modal-title'>Final submit</h4>}
      open={showPublishAndFreezeModal}
      bodyStyle={{ borderRadius: 10 }}
      style={{ borderRadius: '100%' }}
      footer={false}
      maskStyle={{ backgroundColor: 'rgba(30, 34, 37, 0.8)' }}
      onCancel={() => {
        setPublishAndFreezeModal(false);
      }}
    >
      <Form onFinish={onFinish} layout='vertical' requiredMark={false}>
        <Form.Item
          label='Assessment date'
          name='assessmentDate'
          rules={[{ required: true, message: 'Assessment date is required*' }]}
        >
          <RangePicker
            className='input-style'
            showTime={{ format: 'HH:mm' }}
            format='YYYY-MM-DD HH:mm'
            disabledDate={disabledDate}
          />
        </Form.Item>

        <Form.Item label='Notify Users' name='notifyUsersOptions'>
          <Radio.Group
            options={optionsWithDisabled}
            onChange={handleRadioChange}
            optionType='button'
            buttonStyle='solid'
          />
        </Form.Item>

        <Form.Item label='Select Users' name='selectedUsers'>
          <Select
            mode='multiple'
            style={{
              width: '100%',
              height: 55,
            }}
            disabled={selectedNotifyOption !== 'selected'}
            placeholder='Notify users'
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            maxTagCount={'responsive'}
            options={usersList}
          />
        </Form.Item>

        <div className='flex justify-end mt-2 gap-3'>
          <button
            className='add-questions-button'
            style={{ width: '7.3rem', fontWeight: 600 }}
          >
            Submit
          </button>
        </div>
      </Form>
    </Modal>
  );
};

export default FinishPage;

