/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from '../redux/loaderSlice';
import { SetUser } from '../redux/usersSlice.js';
import { getUserInfo } from '../apicalls/users';
const SideBar = () => {
  const [menu, setMenu] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userMenu = [
    {
      title: 'Home',
      paths: ['/', '/user/write-assessment'],
      icon: <i className='ri-home-line'></i>,
      onClick: () => navigate('/'),
    },
    {
      title: 'Reports',
      paths: ['/user/reports'],
      icon: <i className='ri-bar-chart-line'></i>,
      onClick: () => navigate('/user/reports'),
    },
    {
      title: 'Feedback',
      paths: ['/user/feedback', '/user/feedback/give'],
      icon: <i className='ri-feedback-line'></i>,
      onClick: () => navigate('/user/feedback'),
    },
    {
      title: 'Profile',
      paths: ['/user/profile'],
      icon: <i className='ri-user-line'></i>,
      onClick: () => navigate('/user/profile'),
    },
    {
      title: 'Logout',
      paths: ['/logout'],
      icon: <i className='ri-logout-box-line'></i>,
      onClick: () => {
        localStorage.removeItem('token');
        navigate('/login');
      },
    },
  ];

  const adminMenu = [
    {
      title: 'Home',
      paths: ['/', '/user/write-assessment'],
      icon: <i className='ri-home-line'></i>,
      onClick: () => navigate('/'),
    },
    {
      title: 'Assessment',
      paths: ['/admin/assessment', '/admin/assessment/add'],
      icon: <i className='ri-file-list-line'></i>,
      onClick: () => navigate('/admin/assessment'),
    },
    {
      title: 'Reports',
      paths: ['/admin/reports'],
      icon: <i className='ri-bar-chart-line'></i>,
      onClick: () => navigate('/admin/reports'),
    },
    {
      title: 'Feedback',
      paths: ['/admin/feedback', '/admin/feedback/add', '/admin/feedback/view'],
      icon: <i className='ri-feedback-line'></i>,
      onClick: () => navigate('/admin/feedback'),
    },
    {
      title: 'Profile',
      paths: ['/admin/profile'],
      icon: <i className='ri-user-line'></i>,
      onClick: () => navigate('/admin/profile'),
    },
    {
      title: 'Logout',
      paths: ['/logout'],
      icon: <i className='ri-logout-box-line'></i>,
      onClick: () => {
        localStorage.removeItem('token');
        navigate('/login');
      },
    },
  ];

  const superAdminMenu = [
    {
      title: 'Home',
      paths: ['/', '/user/write-assessment'],
      icon: <i className='ri-home-line'></i>,
      onClick: () => navigate('/'),
    },
    {
      title: 'Assessment',
      paths: ['/admin/assessment', '/admin/assessment/add'],
      icon: <i className='ri-file-list-line'></i>,
      onClick: () => navigate('/admin/assessment'),
    },
    {
      title: 'Reports',
      paths: ['/admin/reports'],
      icon: <i className='ri-bar-chart-line'></i>,
      onClick: () => navigate('/admin/reports'),
    },
    {
      title: 'Feedback',
      paths: ['/admin/feedback', '/admin/feedback/add', '/admin/feedback/view'],
      icon: <i className='ri-feedback-line'></i>,
      onClick: () => navigate('/admin/feedback'),
    },
    {
      title: 'Profile',
      paths: ['/admin/profile'],
      icon: <i className='ri-user-line'></i>,
      onClick: () => navigate('/admin/profile'),
    },
    {
      title: 'Admin Panel',
      paths: ['/admin/create-admin'],
      icon: <i className='ri-admin-line'></i>,
      onClick: () => navigate('/admin/create-admin'),
    },
    {
      title: 'Logout',
      paths: ['/logout'],
      icon: <i className='ri-logout-box-line'></i>,
      onClick: () => {
        localStorage.removeItem('token');
        navigate('/login');
      },
    },
  ];
  

  const activeRoute = window.location.pathname;

  const getIsActiveOrNot = (paths) => {
    if (paths.includes(activeRoute)) {
      return true;
    } else {
      if (
        activeRoute.includes('/admin/assessment/edit') &&
        paths.includes('/admin/assessment')
      ) {
        return true;
      }
      if (
        activeRoute.includes('/user/write-assessment') &&
        paths.includes('/user/write-assessment')
      ) {
        return true;
      }
      if( activeRoute.includes('/admin/feedback/view') &&
      paths.includes('/admin/feedback')){
        return true
      }
      if(activeRoute.includes('/user/feedback/give')&&paths.includes('/user/feedback')){
        return true
      }
    }
    return false;
  };
  const getUserData = async () => {
    try {
      dispatch(ShowLoading());
      const response = await getUserInfo();
      dispatch(HideLoading());
      if (response.status.success) {
        dispatch(SetUser(response.result));
        if (response?.result?.isSuperAdmin) {
          setMenu(superAdminMenu);
        } else if (response.result.isAdmin) {
          setMenu(adminMenu);
        } else {
          setMenu(userMenu);
        }
      } else {
        message.error(response.message);
      }
    } catch (error) {
      navigate('/login');
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      getUserData();
    } else {
      navigate('/login', { state: location });
    }
  }, []);
  return (
    <div className='sidebar'>
      <div className='menu'>
        {menu.map((item, index) => {
          return (
            <div
              className={`menu-item ${
                getIsActiveOrNot(item.paths) && 'active-menu-item'
              }`}
              key={index}
              onClick={item.onClick}
            >
              {item.icon}
              { <span>{item.title}</span>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SideBar;
