/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal,message } from 'antd';
import moment from 'moment';
import './style.css';
import usePageVisibility from '../../../utils/hooks/usePageVisibility';
// import CustomAlertModal from '../../../components/CustomAlertModal';
import instruction_page from '../../../assets/images/instruction_page.png';
import { RightOutlined } from '@ant-design/icons';
import { authorizeAssessment } from '../../../apicalls/exams';

function Instructions({ examData, setView, startTimer }) {
  const navigate = useNavigate();
  const visibility = usePageVisibility();
  // const [open, setOpen] = useState(false);
  const [isUserAuthorizedToTakeAssessment, setIsUserAuthorizedToTakeAssessment] = useState(true)
  useEffect(() => {
    if (visibility) {
      setOpen(true);
    }
  }, [visibility]);
  const formtedTime = moment
    .utc(examData.duration * 60 * 1000)
    .format('HH:mm:ss');
  const handleStartExam = () => {
    if(!isUserAuthorizedToTakeAssessment){
      message.error(`You don't have access for this assessment`)
      return
    }
    if (examData?.questions?.length <= 0) {
      Modal.info({
        content:
          'Currently, there are no questions available for this Assessment. Please try again later.',
      });
    } else {
      startTimer();
      setView('questions');
    }
  };

 async function authorizedUserAssessment(){
  try {
  const response =  await authorizeAssessment(examData._id)
  if(response.status.success&&response.result.auth){
    setIsUserAuthorizedToTakeAssessment(response.result.auth)
  }else{
    setIsUserAuthorizedToTakeAssessment(false)
    message.error(`You don't have access for this assessment`)
  }
  
  } catch (error) {
    setIsUserAuthorizedToTakeAssessment(false)
    message.error(`You don't have access for this assessment`)
  }
  }
  useEffect(()=>{
    authorizedUserAssessment()
  },[])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '50%' }}>
          <ul className='flex flex-col gap-1'>
            <h1 className='text-2xl'>Instructions</h1>
            <li>Exam must be completed in {formtedTime}</li>
            <li>Exam will be submitted automatically after {formtedTime}</li>
            <li>Once submitted, you cannot change your answers.</li>
            <li>Do not refresh the page.</li>
            <li>
              You can use the <span className='font-bold'>`Previous`</span> and{' '}
              <span className='font-bold'>`Next`</span> buttons to navigate
              between questions.
            </li>
            <li>
              Total marks of the exam is{' '}
              <span className='font-bold'>{examData.totalMarks}</span>.
            </li>
            <li>
              Passing marks of the exam is{' '}
              <span className='font-bold'>{examData.passingMarks}</span>.
            </li>
          </ul>
          <div className='Footer_Button_Wrapper'>
            <button className='close-button' onClick={() => navigate('/')}>
              Close
            </button>
            <button className='Global_Primary_Button' onClick={handleStartExam}>
              <span>Start</span>

              <RightOutlined style={{ marginLeft: 10 }} />
            </button>
          </div>
        </div>
        <div style={{ width: '50%' }}>
          <img src={instruction_page} alt='instruction' />
        </div>
      </div>
      {/* <CustomAlertModal {...{ open, setOpen }} /> */}
    </>
  );
}

export default Instructions;
