/* eslint-disable no-console */
import axiosInstance from '.';

// add report
export const addCourse = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/api/feedBack/add-course`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getFeedbackQuestions = async (feedbackId) => {
  try {
    const response = await axiosInstance.get(
      `/api/feedback/questions/${feedbackId}`
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const addQuestionToFeedback = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/api/feedBack/add-questions`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const editFeedbackReport = async (payload) => {
  try {
    const response = await axiosInstance.put(
      `/api/feedBack/update/course`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getFeedbackReportById = async (feedbackId,search) => {
  let url= `/api/feedback/reports/${feedbackId}`
  if(search){
    url=`/api/feedback/reports/${feedbackId}?search=${search}`
  }
  try {
    const response = await axiosInstance.get(
     url
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// feedback
export const getAllCourseFeedback = async ()=>{
  try {
   const response = await axiosInstance.get('/api/feedBack/courses');
   return response.data
  } catch (error) {
   return error.response.data;
  }
}

// Feedback Question List
export const getAllFeedbackQuestion = async (feedbackId)=>{
  try {
     const response = await axiosInstance.get(`/api/feedback/questions/${feedbackId}`);
    return response.data
  } catch (error) {
    return error.response.data;
  }
}
// Submit Feedback Report from User
export const submitFeedbackReport = async (payload)=>{
  try {
    const response = await axiosInstance.post('api/feedback/submit-feedback-reports',payload)
    return response.data
  } catch (error) {
    return error.response;
  }
}