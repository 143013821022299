/* eslint-disable new-cap */
import { Tabs, message } from 'antd';
import React, { useState } from 'react';
import AddEditAssessment from './AddEditAssessment';
import FinishPage from './FinishPage';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import { addExam, editExamById } from '../../../apicalls/exams';
import AddEditQuestions from './AddEditQuestions';
import './styles.css';

const MultiStepForm = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [current, setCurrent] = useState(1);
  const [examId, setExamId] = useState(null);
  const [assessmentData, setAssessmentData] = useState(null);

  useState(() => {
    setExamId(params.id);
  }, []);

  const onAssessmentDetailsFinish = async (values) => {
    try {
      dispatch(ShowLoading());
      let response;
      if (examId) {
        response = await editExamById({
          ...values,
          examId: examId,
          startExamDate: '',
          endExamDate: '',
        });
      } else {
        const formData = {
          ...values,
          startExamDate: '',
          endExamDate: '',
        };
        response = await addExam(formData);
        setExamId(response?.result?._id);
      }
      if (response.status.success) {
        message.success(response.status.message);

        setCurrent(current + 1);
      } else {
        message.error(response.error.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const items = [
    {
      key: 1,
      label: `Assessment Details`,
      children: <AddEditAssessment onFinish={onAssessmentDetailsFinish} />,
    },
    {
      key: 2,
      label: `Questionnaire`,
      children: (
        <AddEditQuestions
          examId={examId}
          current={current}
          setCurrent={setCurrent}
          setAssessmentData={setAssessmentData}
        />
      ),
    },
    {
      key: 3,
      label: `Confirm`,
      children: (
        <FinishPage
          examId={examId}
          assessmentData={assessmentData}
          current={current}
          setCurrent={setCurrent}
        />
      ),
    },
  ];

  return (
    <diV>
      <div style={{ fontWeight: 600, fontSize: 24 }}>
        {params.id ? 'Edit Assessment' : 'Add Assessment'}
      </div>
      <div className='divider'></div>
      <Tabs
        style={{ marginTop: '2rem' }}
        defaultActiveKey={1}
        items={items}
        activeKey={current}
      />
      ;
    </diV>
  );
};
export default MultiStepForm;
