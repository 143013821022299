import googleLogo from './google-logo.png';
import uploadIcon from './upload_icon.png';
import timerIcon from './timer_icon.png';
import placeholderAvatar from './placeholder_avatar.png';
import notFoundErrorImage from './404.png';
import exportIcon from './export_icon.png';
import doneIcon from './done_icon.png'

export const IMAGES = {
  googleLogo,
  uploadIcon,
  timerIcon,
  placeholderAvatar,
  notFoundErrorImage,
  exportIcon,
  doneIcon
};
