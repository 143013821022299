/* eslint-disable new-cap */
// eslint-disable-next-line react-hooks/rules-of-hooks
import React,{useEffect} from 'react';
import { useDispatch,useSelector} from 'react-redux';
import {  message } from 'antd';
import { HideLoading,ShowLoading } from '../../../../redux/loaderSlice';
import { getAllExams } from '../../../../apicalls/exams';


const useExamData= () => {
    const [exams, setExams] = React.useState([]);
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.users);
    // eslint-disable-next-line no-console
    const getExams = async () => {
        try {
          dispatch(ShowLoading());
          setTimeout(async () => {
            const response = await getAllExams();
            if (response?.status?.success) {
              setExams(() => response.result);
            } else {
              message.error(response?.status?.message);
            }
            dispatch(HideLoading());
          }, 100);
        } catch (error) {
          dispatch(HideLoading());
          message.error(error.message);
        }
      };
    useEffect(()=>{
      if(user){
        getExams();
      }
        
    },[])
  return {exams}
}

export default useExamData