import axiosInstance from '.';

// add report
export const addReport = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/api/reports/add-report`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// get all reports
export const getAllReports = async (search) => {
  try {
    let url = `/api/reports/get-all-reports`;
    if (search) {
      url = `/api/reports/get-all-reports?userName=${search}`;
    }
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// get all reports by user
export const getAllReportsByUser = async () => {
  try {
    const response = await axiosInstance.get(
      `/api/reports/get-all-reports-by-user`
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const getUserAssesmentRepors = async (userId) => {
  try {
    const url = `/api/exams/get-user-assessments/${userId}`;
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
