import React from 'react';
import './buttonStyles.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { COLORS } from '../../constants';
import { IMAGES } from '../../assets/images';

const CommonButton = (props) => {
  const { onClick, label, disabled, type } = props;

  return (
    <button
      className='common-button'
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {label ? label : 'Click Me'}
    </button>
  );
};
const AddButton = (props) => {
  const { label, onClick, disabled, type } = props;
  return (
    <div className='add-button-container'>
      <button
        className='add-button'
        disabled={disabled}
        type={type}
        onClick={onClick}
      >
        <i className='ri-add-line'> </i>
        <span style={{ position: 'relative', bottom: '4px', marginLeft: 5 }}>
          {label ? label : 'Add'}
        </span>
      </button>
    </div>
  );
};

const PrevButton = (props) => {
  const { disabled, onClick, label, type } = props;
  return (
    <button
      disabled={disabled}
      className='next-prev-button prev-button'
      type={type}
      onClick={onClick}
    >
      <LeftOutlined style={{ marginRight: 10 }} />
      <span>{label ? label : 'Previous'}</span>
    </button>
  );
};
const NextButton = (props) => {
  const { disabled, label, onClick, type } = props;
  const backgroundColor = disabled ? COLORS.primary_grey : COLORS.primary_green;
  return (
    <button
      disabled={disabled}
      className='next-prev-button next-button'
      type={type}
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
    >
      <span> {label ? label : 'Next'}</span>

      <RightOutlined style={{ marginLeft: 10 }} />
    </button>
  );
};
const CancelButton = (props) => {
  const { onClick, label, disabled, type } = props;
  return (
    <button
      style={{
        color: COLORS.alert_light_red,
        borderColor: COLORS.alert_light_red,
      }}
      disabled={disabled}
      type={type}
      className='next-prev-button prev-button'
      onClick={onClick}
    >
      {label ? label : 'Cancel'}
    </button>
  );
};
const OutlinedButton = (props) => {
  const { disabled, label, onClick } = props;
  return (
    <button
      disabled={disabled}
      className='next-prev-button prev-button'
      onClick={onClick}
    >
      {label ? label : 'Click'}
    </button>
  );
};
const ExportButton = (props) => {
  const { onClick } = props;
  return (
    <div className='export-button-container'>
      <div className='export-icon-container' onClick={onClick}>
        <img
          src={IMAGES.exportIcon}
          style={{ height: 18 }}
          alt='export Icon'
        ></img>
      </div>
    </div>
  );
};
export {
  CommonButton,
  NextButton,
  PrevButton,
  AddButton,
  CancelButton,
  OutlinedButton,
  ExportButton,
};

