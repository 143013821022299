/* eslint-disable no-console */
/* eslint-disable new-cap */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Row, Col, message,Empty } from 'antd';
import { useDispatch } from 'react-redux';
import Card from './Card';
import { HideLoading, ShowLoading } from '../../redux/loaderSlice';
import { getAllCourseFeedback } from '../../apicalls/feedback';

const ListFeedBacks = () => {
  const dispatch = useDispatch();
  const [coursesFeedBack, setCoursesFeedBAck] = useState([]);
  async function getFeedAllFeedback() {
    dispatch(ShowLoading());
    try {
      const response = await getAllCourseFeedback();
      if (response?.status?.success) {
        dispatch(HideLoading());
        response.result;
        setCoursesFeedBAck(response.result);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  }
  useEffect(() => {
    getFeedAllFeedback();
  }, []);
  return (
    <Row gutter={[16, 16]}>
      {coursesFeedBack.length>0 ?coursesFeedBack?.map((item, index) => (
        <Col key={index} >
          <Card
            id={item?._id}
            courseCode={item.course_code}
            trainerNameList={item.trainer_name_list}
            courseName={item.course_name}
            courseDuration={item.course_duration}
            attemptedUserList={item?.attempted_user_list}
            accessUserList={item?.access_user_list}
          />
        </Col>
      )): <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col
        className='gutter-row'
        span={24}
        style={{width:'100vw'}}
      >
         <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Col>
    </Row>}
    </Row>
  );
};

export default ListFeedBacks;
