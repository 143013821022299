import { Modal } from 'antd';
import React from 'react';
import { IMAGES } from '../../assets/images';
import './styles.css'

export const EmailSentModal=(props)=>{
    const {showEmailSentModal,setShowEmailSentModal}=props;
    setTimeout(()=>{
        setShowEmailSentModal(false)
    },3000)
return(
    <Modal
    open={showEmailSentModal}
    maskStyle={{ backgroundColor: 'rgba(30, 34, 37, 0.8)' }}
    footer={false}
   
   >
   
     <div className='email-sent-modal-container'>
      <img src={IMAGES.doneIcon} alt='done icon' className='done-icon-style'></img>
      <p className='email-sent-text'>Email has been dispatched to selected users.</p>
      </div>
  </Modal>
)
}