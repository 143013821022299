import React from 'react';
import moment from 'moment';
import './card.css';
import { useNavigate } from 'react-router-dom';
import { Tag } from 'antd';

const Card = (props) => {
  const {
    item,
    isDisabled,
    result,
    category,
    duration,
    startExamDate,
    endExamDate,
    attemptedDate,
    buttonName,
    isInProgress,
    isExpired,
  } = props;
  const { _id, name } = item;

  const verdict = result?.verdict;
  const navigate = useNavigate();
  const formtedTime = moment.utc(duration * 60 * 1000).format('HH:mm:ss');
  return (
    <div className='Card'>
      <div
        className='card-header'
        style={{ backgroundColor: isExpired ? '#EDEDED' : '#95F1C5' }}
      >
        <h1>{name?.length > 14 ? name?.slice(0, 14) : name}</h1>
      </div>
      <div className='card-content'>
        <div style={{ display: 'flex', width: '95%', flexDirection: 'column' }}>
          {category && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginBottom: '1.2rem',
              }}
            >
              <div style={{ width: '60%', display: 'inline' }}>
                <p className='Card-Body-first-Text'>Category</p>
              </div>
              <div>
                <p className='Card-Body-Text'>{category}</p>
              </div>
            </div>
          )}

          {(startExamDate || isInProgress) && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginBottom: '1.22rem',
              }}
            >
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  lineHeight: '1rem',
                }}
              >
                <p className='Card-Body-first-Text'>Start Date</p>
              </div>
              <div>
                <p className='Card-Body-Text'>
                  {isInProgress
                    ? '................'
                    : moment(startExamDate).format('MM/DD/YYYY')}
                </p>
              </div>
            </div>
          )}

          {(endExamDate || isInProgress) && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginBottom: '1.2rem',
              }}
            >
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  lineHeight: '1rem',
                }}
              >
                <p className='Card-Body-first-Text'>End Date</p>
              </div>
              <div>
                <p className='Card-Body-Text'>
                  {isInProgress
                    ? '................'
                    : moment(endExamDate).format('MM/DD/YYYY')}
                </p>
              </div>
            </div>
          )}

          {duration && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginBottom: '1.2rem',
              }}
            >
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  lineHeight: '1rem',
                }}
              >
                <p className='Card-Body-first-Text'>Duration</p>
              </div>
              <div>
                <p className='Card-Body-Text'>{formtedTime}</p>
              </div>
            </div>
          )}
          {attemptedDate && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginBottom: '1.2rem',
              }}
            >
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  lineHeight: '1rem',
                }}
              >
                <p className='Card-Body-first-Text'>Attempted Date</p>
              </div>
              <div>
                <p className='Card-Body-Text'>
                  {moment(attemptedDate).format('MM/DD/YYYY')}
                </p>
              </div>
            </div>
          )}
          {verdict && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginBottom: '1.2rem',
              }}
            >
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  flexWrap: 'wrap',
                  lineHeight: '1rem',
                }}
              >
                <p className='Card-Body-first-Text'>Result</p>
              </div>
              <div>
                <Tag
                  style={{ borderRadius: 4 }}
                  color={verdict === 'Pass' ? 'green' : 'red'}
                >
                  {verdict}
                </Tag>
              </div>
            </div>
          )}
        </div>
        <div className={isDisabled ? 'deactive-button ' : 'card-footer'}>
          <button onClick={() => navigate(`/user/write-assessment/${_id}`)}>
            {buttonName}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Card;
