import { combineReducers } from 'redux';
import usersSlice from './usersSlice';
import loaderSlice from './loaderSlice';
import feedbackSlice from './feedBackSlice'
const reducers = {
  users: usersSlice,
  loader: loaderSlice,
  feedback: feedbackSlice
};
export const rootReducer = combineReducers(reducers);
