
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import { Col, message, Row, Table } from 'antd';
import { useDispatch } from 'react-redux';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import { getAllReports ,getUserAssesmentRepors} from '../../../apicalls/reports';

import './styles.css';
import CustomSearch from '../../../components/Search/CustomSearch';

import { ExportButton } from '../../../components/customButtons/customButtons';
import { saveAs } from 'file-saver';
import { formatNumber } from '../../../utils/commonFunctions/commonFunctions';

function AdminReports() {
  const [reportsData, setReportsData] = React.useState([]);
  const dispatch = useDispatch();

  const columns = [
    {
      title: 'User',
      dataIndex: 'name',
    },
    {
      title: 'Attempted',
      dataIndex: 'attemptedExams',
    },
    {
      title: 'Passed',
      dataIndex: 'passedExams',
    },
    {
      title: 'Failed',
      dataIndex: 'failedExams',
    },
    {
      title: 'Missed',
      dataIndex: 'missedExams',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      render: (text, record) => `${ formatNumber(record?.percentage)}%`,
    },
  ];
 
  const handleExport = () => {
    const exportData = reportsData.map((item) => {
      return {
        User: item.name,
        Attempted_Assessments: item.attemptedExams || 0,
        Passed_Assessments: item.passedExams || 0,
        Failed_Assessment: item.failedExams || 0,
        Missed_Assessment: item.missedExams || 0,
        Percentage: item.percentage || 0};
    });

    const convertToCSV = (data) => {
      const header = Object.keys(data[0]).join(',') + '\n';
      const rows = data.map((item) => Object.values(item).join(',')).join('\n');
      return header + rows;
    };

    const csvData = convertToCSV(exportData);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'Report.csv');
  };

  const getData = async (searchTerm) => {
    try {
      dispatch(ShowLoading());
      const response = await getAllReports(searchTerm);

      if (response.status.success) {
        const dataWithKey = response.result.map((each) => ({
          ...each,
          key: each.id,
        }));
        setReportsData(dataWithKey);
      } else {
        message.error(response.error.message);
      }
      dispatch(HideLoading());
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const onSearch = (e) => {
    const value = e.target.value;

    if (e.key === 'Enter' || value.length === 0) {
      getData(value);
    }
  };

  useEffect(() => {
    getData();
  }, []);


  const expandedRowRender = (record) => {
    return <NestedTable record={record} />;
  };
  return (
    <div>
      <PageTitle title='Reports' />
      <div className='divider'></div>
      <div>
        <Row style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Col span={11}>
            <CustomSearch
              // onChange={onSearch}
              placeholder={'Search Users'}
              onKeyUp={onSearch}
            />
          </Col>
          <Col>
            <Col span={8}>
              <ExportButton onClick={handleExport} />
            </Col>
          </Col>
        </Row>
      </div>

      <Table
        columns={columns}
        dataSource={reportsData}
        className='mt-2'
        expandedRowRender={expandedRowRender}
      />
    </div>
  );
}

export default AdminReports;


const NestedTable = ({ record }) => {
  const [nestedData, setNestedData] = useState([]);
  const columnsNestedTable = [
    {
            title: 'Assessment Name',
            dataIndex: 'assessment_name',
          },
          {
            title: 'Attempted Count',
            dataIndex: 'attempted_count',
          },
          {
            title: 'Pass Count',
            dataIndex: 'pass_count',
          },
          {
            title: 'Fail Count',
            dataIndex: 'fail_count',
          },
          {
            title: 'Missed Count',
            dataIndex: 'missed_count',
          },
          {
            title: 'Scored',
            dataIndex: 'scored',
            render: (text, record) => `${formatNumber(record?.scored)}%`,
          },
  ];

  useEffect(() => {
    const fetchNestedData = async () => {
      try {
        const { id } = record;
        const { result, status } = await getUserAssesmentRepors(id);
        if (status.success) {
          const dataWithKey = result.map((each) => ({
            ...each,
            key: each.id,
          }));
          setNestedData(dataWithKey);
        }
      } catch (error) {
        message.error(error.message);
      }
    };
    fetchNestedData();
  }, [record]);

  return (
   
    <Table
      columns={columnsNestedTable}
      dataSource={nestedData}
      showHeader={true}
      className='mt-2'
      pagination={false}
      size='small'
    />
  );
};
