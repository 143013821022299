/* eslint-disable new-cap */
import React from 'react';
import { Col, Form, Input, Row, message } from 'antd';

import { useForm } from 'antd/lib/form/Form';
import {
  CancelButton,
  NextButton,
} from '../../components/customButtons/customButtons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addCourse } from '../../apicalls/feedback';
import { HideLoading, ShowLoading } from '../../redux/loaderSlice';

const AddFeedBack = (props) => {
  const dispatch = useDispatch();
  const { setCurrent, setFeedbackId } = props;
  const [form] = useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const { courseDuration, courseId, courseName, empId, trainerName } = values;
    const payload = {
      course_code: courseId,
      trainer_name_list: [trainerName],
      course_name: courseName,
      course_duration: courseDuration,
      emp_id: empId,
    };
    try {
      dispatch(ShowLoading());
      const response = await addCourse(payload);
      dispatch(HideLoading());
      if (response.status.success) {
        setFeedbackId(response?.result?._id);
        setCurrent((current) => current + 1);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  return (
    <div style={{ height: '63vh' }}>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        requiredMark={false}
      >
        <Row style={{ justifyContent: 'space-between' }}>
          <Col span={10}>
            <Form.Item
              style={{ fontWeight: 500 }}
              label='Trainer Name'
              name='trainerName'
              rules={[{ required: true, message: 'Trainer Name is required*' }]}
            >
              <Input
                type='text'
                className='input-style'
                placeholder='Enter trainer Name'
              />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              label='Employee ID of Trainer'
              name='empId'
              style={{ fontWeight: 500 }}
              rules={[
                {
                  required: true,
                  message: 'Employee ID of Trainer is required*',
                },
              ]}
            >
              <Input
                type='text'
                className='input-style'
                placeholder='Enter employee ID of trainer'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ justifyContent: 'space-between' }}>
          <Col span={10}>
            <Form.Item
              style={{ fontWeight: 500 }}
              label='Course Name'
              name='courseName'
              rules={[{ required: true, message: 'Course Name is required*' }]}
            >
              <Input
                type='text'
                className='input-style'
                placeholder='Enter course name'
              />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              label='Course ID'
              name='courseId'
              style={{ fontWeight: 500 }}
              rules={[{ required: true, message: 'Course ID required*' }]}
            >
              <Input
                type='text'
                className='input-style'
                placeholder='Enter course ID'
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Form.Item
              style={{ fontWeight: 500 }}
              label='Course Duration'
              name='courseDuration'
              rules={[
                { required: true, message: 'Course Duration is required*' },
              ]}
            >
              <Input
                type='number'
                className='input-style'
                placeholder='Enter course duration (Minutes)'
              />
            </Form.Item>
          </Col>
        </Row>
        <div className='next-prev-button-container'>
          <CancelButton
            label='Cancel'
            onClick={() => navigate('/admin/feedback')}
          />

          <NextButton label='Next' type='submit' />
        </div>
      </Form>
    </div>
  );
};

export default AddFeedBack;

