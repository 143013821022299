/* eslint-disable new-cap */
import { Col, message, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';

import PageTitle from '../../../components/PageTitle';
import CustomSearch from '../../../components/Search/CustomSearch';
import CustomRadioButton from '../../../components/radioButton/CustomRadio';
import {
  CommonButton,
  ExportButton,
} from '../../../components/customButtons/customButtons';
import { HideLoading, ShowLoading } from '../../../redux/loaderSlice';
import { getAssessmentPreview, sendInvites } from '../../../apicalls/exams';

import './style.css';

function Preview() {
  const [previewData, setPreviewData] = React.useState();
  const [selectedRadio, setSelectedRadio] = useState('total');
  const [users, setUsers] = useState([]);
  const [attemptedList, setAttemptedList] = useState([]);
  const [unattemptedList, setUnattemptedList] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [totalUsersList, setTotalUsersList] = useState([]);

  const dispatch = useDispatch();
  const params = useParams();

  const getAssessmentData = async () => {
    const examId = params.id;

    try {
      dispatch(ShowLoading());
      const response = await getAssessmentPreview(examId);
      dispatch(HideLoading());
      if (response.status.success) {
        setPreviewData(response?.result);

        const totalInviteUsersList =
          response?.result?.total_invite_users_list.map(
            (each) => (each = { ...each, key: each?._id })
          );
        const newAttemptedList = totalInviteUsersList.filter(
          (each) => each.attempt
        );
        const newUnattemptedList = totalInviteUsersList.filter(
          (each) => !each.attempt
        );
        setUsers(totalInviteUsersList);
        setTotalUsersList(totalInviteUsersList);
        setAttemptedList(newAttemptedList);
        setUnattemptedList(newUnattemptedList);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };
  useEffect(() => {
    getAssessmentData();
  }, []);

  const onSearch = (e) => {
    const searchTerm = e.target.value;
    const filteredResults = totalUsersList.filter((item) =>
      item?.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (searchTerm !== '') {
      setUsers(filteredResults);
    } else {
      setUsers(totalUsersList);
    }
  };
  const handleSendInvites = async () => {
    const payload = { examId: params?.id, notify_user_list: selectedUsersList };
    try {
      const response = await sendInvites(payload);
      if (response.status.success) {
        message.success(response?.status?.message);
      } else {
        message.error(response.error.message);
      }
    } catch (error) {
      dispatch(HideLoading());
      message.error(error.message);
    }
  };

  const handleExport = () => {
    const exportData = users.map((item) => {
      return {
        User: item.name,
        Status: item.attempt ? 'Attempted' : 'Unattempted',
      };
    });

    const convertToCSV = (data) => {
      const header = Object.keys(data[0]).join(',') + '\n';
      const rows = data.map((item) => Object.values(item).join(',')).join('\n');
      return header + rows;
    };

    const csvData = convertToCSV(exportData);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, 'Preview_Report.csv');
  };

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
    if (value === 'attempted') {
      setUsers(attemptedList);
    } else if (value === 'unattempted') {
      setUsers(unattemptedList);
    } else {
      setUsers(previewData?.total_invite_users_list);
    }
  };

  const totalCount = previewData?.total_invite_count
    ? previewData?.total_invite_count
    : 0;
  const attemptedCount = previewData?.attempted_count
    ? previewData?.attempted_count
    : 0;
  const unattemptedCount = previewData?.unattempted_count
    ? previewData?.unattempted_count
    : 0;

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedUsersList(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedUsersList,
    onChange: onSelectChange,
  };

  const rowClassName = (record, index) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };

  const columns = [
    {
      title: 'Select All',
      dataIndex: '_id',
      width: '20%',
      render: (val) => '',
    },
    {
      title: 'Users',
      dataIndex: 'name',
      key: 'users',
    },
    {
      title: 'Status',
      dataIndex: 'attempt',
      key: 'status',
      render: (isAttempted) => {
        return isAttempted === 1 ? 'Attempted' : 'Unattempted';
      },
    },
  ];
  return (
    <div className='preview-main-container'>
      <div className='flex justify-between mt-2 items-end'>
        <PageTitle title='Preview Assessment' />
      </div>
      <div className='divider'></div>
      <div className='search-filter-container'>
        <Row style={{ marginBottom: 20 }}>
          <Col span={8}>
            <CustomSearch
              onChange={onSearch}
              placeholder={'Search Users'}
              onKeyDown={onSearch}
            />
          </Col>

          <Col span={5}>
            <CustomRadioButton
              checked={selectedRadio === 'total'}
              onClick={() => handleRadioChange('total')}
              value='total'
              label={`Total Invites: ${totalCount}`}
            />
          </Col>
          <Col span={5}>
            <CustomRadioButton
              checked={selectedRadio === 'attempted'}
              onClick={() => handleRadioChange('attempted')}
              value='attempted'
              label={`Attempted: ${attemptedCount}`}
            />
          </Col>
          <Col span={5}>
            <CustomRadioButton
              checked={selectedRadio === 'unattempted'}
              onClick={() => handleRadioChange('unattempted')}
              value='unattempted'
              label={`Unattempted: ${unattemptedCount}`}
            />
          </Col>

          <Col span={1}>
            <ExportButton onClick={handleExport} />
          </Col>
        </Row>
      </div>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={users}
        rowClassName={rowClassName}
      />

      <CommonButton label='Send Invites' onClick={handleSendInvites} />
    </div>
  );
}

export default Preview;
