import axiosInstance from '.';

export const addExam = async (payload) => {
  try {
    const response = await axiosInstance.post(`/api/exams/add`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// get all exams
export const getAllExams = async () => {
  try {
    const response = await axiosInstance.get(`/api/exams/get-all-exams`);

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// get exam by id

export const getExamById = async (payload) => {
  const examId = payload?.examId;
  try {
    const response = await axiosInstance.get(
      `/api/exams/get-exam-by-id/${examId}`
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// edit exam by id

export const editExamById = async (payload) => {
  try {
    const response = await axiosInstance.put(
      `/api/exams/edit-exam-by-id`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// delete exam by id

export const deleteExamById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/api/exams/delete-exam-by-id`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// add question to exam

export const addQuestionToExam = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/api/exams/add-question-to-exam`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const editQuestionById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/api/exams/edit-question-in-exam`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

export const deleteQuestionById = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/api/exams/delete-question-in-exam`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// get exam preview

export const getAssessmentPreview = async (examId) => {
  try {
    const response = await axiosInstance.get(
      `/api/exams/get-exam-preview/${examId}`
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
export const sendInvites = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `/api/exams/send-reminder`,
      payload
    );

    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

// close hide exam
export const hideCloseExam = async (examId, status) => {
  try {
    const payload = { examId, status };
    const response = await axiosInstance.post(
      '/api/exams/delete-hide-assessment',
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
// AUthorizedAssessment
export const authorizeAssessment=async(assessmentId)=>{
  try {
   const response = await axiosInstance.get(`/api/users/authorize/${assessmentId}`)
   return response.data
  } catch (error) {
    return error.response.data;
  }
}


