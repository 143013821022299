/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import logo_secondary from '../assets/images/logo_secondary.png';
import { IMAGES } from '../assets/images';

const CustomHeader = () => {
  const { user, profileImageUrl } = useSelector((state) => state.users);
  const role = () => {
    if (user?.isSuperAdmin) {
      return 'Super Admin';
    } else if (user?.isAdmin) {
      return 'Admin';
    } else {
      return 'User';
    }
  };
  return (
    <div className='header' style={{ display: 'flex' }}>
      <h1 className='header_main' style={{ width: '80%' }}>
        <img src={logo_secondary} alt='' />
      </h1>
      <div
        style={{
          width: '20%',
          position: 'absolute',
          right: '0',
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <img
          alt='Avatar_img'
          src={
            profileImageUrl ? `${profileImageUrl}` : IMAGES.placeholderAvatar
          }
          style={{
            marginRight: 10,
            height: 50,
            width: 50,
            borderRadius: '100%',
          }}
        />
        <div>
          <h1
            className='text-md header-main-text'
            style={{ textTransform: 'capitalize' }}
          >
            {user?.name}
          </h1>
          <span className='header-main-text'>
            <span className='text-md header-main-text'>Role:</span> {role()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomHeader;

