/* eslint-disable react/prop-types */
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import './searchStyles.css';
import { COLORS } from '../../constants/colors';

const CustomSearch = (props) => {
  const { placeholder, onChange, onKeyDown, onKeyUp } = props;
  return (
    <div className='custom-search-container'>
      <SearchOutlined style={{ flex: 0.1, color: COLORS.text_grey }} />
      <input
        style={{
          height: '99%',
          flex: 0.9,
          marginLeft: '.5rem',
          outline: 'none',
          border: 'none',
          borderRadius: 6,
        }}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      />
    </div>
  );
};
export default CustomSearch;
